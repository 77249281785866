export function metersToMiles (meters) {
  const miles = meters * 0.000621371
  return parseFloat(miles.toFixed(1))
};

export function secondsToTime (secs) {
  const hours = Math.floor(secs / 3600)
  const mins = Math.floor((secs - (hours * 3600)) / 60)
  if (hours > 0) {
    return `${hours}h ${mins}m`
  }
  return `${mins}m`
};

export function colorToHex (color) {
  switch (color) {
    case 'orange':
      return '#fba84c'
    case 'maroon':
      return '#c98c77'
    case 'green':
      return '#86ab4c'
    case 'blue':
      return '#40b1cf'
    case 'yellow':
      return '#e7e250'
    case 'light-blue':
      return '#a7ccd5'
    case 'light-purple':
      return '#d7cde5'
    case 'light-orange':
      return '#e5bf74'
    case 'light-green':
      return '#9fc86a'
    default:
      return '#aaa'
  }
};
