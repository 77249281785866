import styled from 'styled-components'
import colors from '../colors'

export default styled.a`
  color: ${colors.viking};
  transition: all 0.3s ease;
  text-decoration: none;
  align-self: flex-end;

  &:hover {
    color: ${colors.vikingDark};
  }
`
