import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { formatPlaceResultAsWinery, geolocationStates } from '../../../../helpers/maps'
import { colors } from '../../../common'
import LocationIcon from '../../assets/LocationIcon'
import LocationLoadingIcon from '../../assets/LocationLoadingIcon'
import LocationSuccessIcon from '../../assets/LocationSuccessIcon'
import { darken } from 'polished'
import { StartAddressDispatchContext } from '../../StartAddressContext'
import { updateStartAddressModel } from '../../helpers'
import { UserContext } from '../../UserContext'

const SearchGeolocation = styled.a`
  text-decoration: none;
  font-weight: normal;
  font-size: 0.875rem;
  color: ${(props) => props.color};
  display: inline-flex;
  align-items: center;
  margin-top: 0.28125rem;
  cursor: pointer;
  transition: 0.15s ease-in;

  svg {
    margin-right: 0.33125rem;
    fill: ${(props) => props.color};
    transition: 0.15s ease-in;
  }

  &:hover {
    color: ${(props) => darken(0.15, props.color)};

    svg {
      fill: ${(props) => darken(0.15, props.color)};
    }
  }
`

export function GeolocationContent ({ onGeoLocate, inputRef }) {
  const token = useContext(UserContext)
  const [status, setStatus] = useState(geolocationStates.INACTIVE)
  const startAddressDispatch = useContext(StartAddressDispatchContext)
  const geocoder = useRef(null)

  useEffect(() => {
    geocoder.current = new window.google.maps.Geocoder()
  })

  const handleOnClick = useCallback(() => {
    if (geocoder.current) {
      setStatus(geolocationStates.ACTIVE)
      navigator.geolocation.getCurrentPosition(({ coords }) => {
        const location = {
          location: {
            lat: coords.latitude,
            lng: coords.longitude
          }
        }

        geocoder.current.geocode(location, (placeResults, status) => {
          setStatus(geolocationStates.SUCCESS)
          startAddressDispatch({ type: 'geocode', payload: { geocode: placeResults[0] } })
          if (inputRef.current != null) {
            const formattedPlaceResult = formatPlaceResultAsWinery(placeResults[0])
            updateStartAddressModel(formattedPlaceResult, token)
            inputRef.current.value = formattedPlaceResult.name
          }
        })
      }, () => {
        setStatus(geolocationStates.FAILURE)
      })
    } else {
      setStatus(geolocationStates.FAILURE)
    }
  }, [onGeoLocate, geocoder.current])

  if ('geolocation' in window.navigator) {
    let color, icon, text
    switch (status) {
      case geolocationStates.ACTIVE:
        icon = <LocationLoadingIcon />
        text = 'Finding your location'
        color = colors.viking
        break
      case geolocationStates.SUCCESS:
        icon = <LocationSuccessIcon />
        text = 'Location found'
        color = colors.silverTree
        break
      case geolocationStates.FAILURE:
        icon = null
        text = 'Location could not be found'
        color = colors.roseVale
        break
      default:
        icon = <LocationIcon />
        text = 'Use my current location'
        color = colors.viking
        break
    }
    return (
      <SearchGeolocation
        color={color}
        onClick={handleOnClick}
      >
        {icon}
        {text}
      </SearchGeolocation>
    )
  } else {
    return null
  }
}
