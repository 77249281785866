import React, { useState, useEffect, useRef, useCallback, useContext } from 'react'
import styled from 'styled-components'
import Panel from './components/Panel'
import { ItineraryMap } from './InteractiveMap'
import breakpoints from './breakpoints'
import { colors } from '../common'
import { darken } from 'polished'
import {
  getCoordsFromWinery,
  geolocationStates,
  myItineraryModes
} from '../../helpers/maps'
import {
  generateDirectionsURL,
  getDestination,
  getOrigin,
  getWaypoints,
  getWaypointsCoordinates
} from './helpers'
import { WineriesContext } from './WineriesContext'
import { StartAddressContext } from './StartAddressContext'
import { DirectionsDispatchContext } from './DirectionsContext'

const Container = styled.div`
  -ms-grid-row: 2;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 100%;
  -ms-grid-rows: 100%;
  grid-template-columns: 100%;
  grid-template-rows: 100%;
  overflow: hidden;

  @media print {
    overflow: visible;
  }

  @media (min-width: ${breakpoints.columns}px) {
    -ms-grid-columns: 600px 1fr;
    grid-template-columns: 600px 1fr;
    height: calc(100vh - 72px);
  }
`

const MobileToggle = styled.div`
  background-color: ${colors.roseVale};
  text-align: center;
  font-weight: bold;
  font-size: 1.2rem;
  color: #fff;
  display: inline-block;
  box-sizing: border-box;
  padding: 0.75rem 3.25rem;
  border-radius: 120px;
  position: fixed;
  z-index: 2;
  left: 50%;
  bottom: 1.625rem;
  transform: translate(-50%, 0);
  cursor: pointer;
  transition: 0.3s ease-in;

  &:hover {
    background-color: ${darken(0.15, colors.roseVale)};
  }

  @media (min-width: ${breakpoints.columns}px) {
    display: none;
  }

  @media print {
    display: none;
  }
`

export default function MyItinerary ({ token, loggedIn, googleApiKey }) {
  const wineries = useContext(WineriesContext)
  const startAddress = useContext(StartAddressContext)
  const directionsDispatch = useContext(DirectionsDispatchContext)
  const [directionsResponse] = useState({})
  const [geolocation] = useState(geolocationStates.INACTIVE)
  const [mode, setMode] = useState(myItineraryModes.LIST)
  const [displayDistanceTime, setDisplayDistanceTime] = useState(true)

  const directionsService = useRef(null)
  const geocoder = useRef(null)

  useEffect(() => {
    directionsService.current = new window.google.maps.DirectionsService()
    geocoder.current = new window.google.maps.Geocoder()
  }, [])

  // update directions
  useEffect(() => {
    if (wineries !== undefined && wineries.length > 0) {
      const origin = getOrigin(startAddress, wineries)
      const destination = getDestination(wineries)
      const waypoints = getWaypoints(startAddress, wineries)
      if (origin && destination && directionsService.current) {
        directionsService.current.route({
          origin: getCoordsFromWinery(origin),
          destination: getCoordsFromWinery(destination),
          waypoints: getWaypointsCoordinates(waypoints),
          travelMode: 'DRIVING'
        }, (response, status) => {
          if (status === 'OK') {
            directionsDispatch({ type: 'fetchSucceed', payload: response })
            setDisplayDistanceTime(true)
          } else {
            directionsDispatch({ type: 'fetchFail' })
          }
        })
      }
    }
  }, [directionsService.current, startAddress, wineries])

  const toggleMode = useCallback(() => {
    const { MAP, LIST } = myItineraryModes
    setMode((mode) => mode === MAP ? LIST : MAP)
  }, [setMode])

  const onWineryMouseDown = useCallback(({ target }) => {
    // Don't hide DistanceTime when clicking link
    if (target.tagName !== 'A') {
      setDisplayDistanceTime(false)
    }
  }, [setDisplayDistanceTime])

  return (
    <Container>
      <Panel
        loggedIn={loggedIn}
        startAddress={startAddress}
        geolocation={geolocation}
        directionsURL={generateDirectionsURL(wineries, startAddress, false)}
        directionsResponse={directionsResponse}
        mode={mode}
        onWineryMouseDown={onWineryMouseDown}
        displayDistanceTime={displayDistanceTime}
      />
      <ItineraryMap
        googleApiKey={googleApiKey}
      />
      {/* <InteractiveMap
        startAddress={startAddress}
        wineries={wineries}
        directionsResponse={directionsResponse}
        googleApiKey={googleApiKey}
      /> */}
      <MobileToggle onClick={toggleMode}>
        {mode === myItineraryModes.LIST && 'View Map'}
        {mode === myItineraryModes.MAP && 'View List'}
      </MobileToggle>
    </Container>
  )
}
