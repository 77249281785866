import PropTypes from 'prop-types'
import styled from 'styled-components'

const Background = styled.div`
  width: 100vw;
  background-image: url(${props => props.backgroundImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 5rem 0;
`

Background.propTypes = {
  backgroundImage: PropTypes.string
}

export default Background
