import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import colors from '../colors'

const Container = styled.label`
  display: flex;
  align-items: center;
  padding-left: 40px;

  input {
    display: none;
  }
`

const Toggle = styled.div`
  background-color: #fefefe;
  display: flex;
  align-items: center;
  width: 50px;
  height: 28px;
  position: relative;
  border: 2px solid rgba(0,0,0,0.1);
  border-radius: 28px;
  cursor: pointer;
  transition: 0.3s ease-in;

  input:checked + & {
    background-color: ${colors.silverTree};
    border-color: transparent;
  }

  &:before {
    content: 'No';
    position: absolute;
    right: 60px;
  }

  input:checked + &:before {
    content: 'Yes';
  }

  &:after {
    content: '';
    background-color: #fff;
    width: 28px;
    height: 28px;
    position: absolute;
    top: -1px;
    left: -1px;
    transition: 0.3s ease-in;
    border: solid 0.5px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.05),
                0 2px 2px 0 rgba(0, 0, 0, 0.1),
                0 3px 1px 0 rgba(0, 0, 0, 0.05);
  }

  input:checked + &:after {
    left: 21px;
  }
`

const Checkbox = (props) => (
  <Container>
    <input name={props.name} type='hidden' value='false' />
    <input {...props} type='checkbox' value='true' />
    <Toggle />
  </Container>
)

Checkbox.propTypes = {
  name: PropTypes.string
}

export default Checkbox
