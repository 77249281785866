import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { fonts, Heading } from '../common'

const Container = styled.div`
  padding: 2rem 0;
  display: flex;
  flex-direction: row;
  align-content: center;
`
const RegionHeading = styled(Heading)`
  margin: 0;
  font-family: ${fonts.dinMedium};
`
const RegionHeader = ({ region }) => {
  return (
    <Container>
      <RegionHeading>{region.name}</RegionHeading>
    </Container>
  )
}

RegionHeader.propTypes = {
  region: PropTypes.object
}

export default RegionHeader
