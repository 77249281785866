import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  max-width: 1190px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media(min-width: 1050px) {
    padding: 0 1rem;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`

const Background = styled.div`
  z-index: 0;
  background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(3,4,34,0.6) 100%), url(${props => props.backgroundImage}) no-repeat center;
  background-size: cover;
  position: relative;
  box-sizing: border-box;

  @media(min-width: 1050px) {
    padding: 8rem 0 5rem 0;
  }
`

Background.propTypes = {
  backgroundImage: PropTypes.string
}

export default (props) => (
  <Background backgroundImage={props.backgroundImage}>
    <Wrapper>
      {props.children}
    </Wrapper>
  </Background>
)
