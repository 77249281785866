import styled from 'styled-components'
import fonts from '../common/fonts'

export default styled.h1`
  color: #fff;
  font-size: 3.5rem;
  font-family: ${fonts.dinMedium};
  line-height: 1;
  font-weight: 500;
  margin: 0 0 0.5rem 0;
`
