import React from 'react'
import styled from 'styled-components'
import { colors, Button, Heading, Paragraph } from '../common'
import breakpoints from './breakpoints'
import VisitIcon from '../ActionPill/VisitIcon' 

const Wrapper = styled.div`
  width: 100%;
  background-color: #fff;
  padding: 3rem 0;
  border-top: 1px solid ${colors.quillGrey};

  @media(min-width: ${breakpoints.desktop}) {
    padding: 8rem 0;
  }
`

const Container = styled.div`
  max-width: 350px;
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  margin: 0 auto;
  text-align: center;
`

const NoWineriesHeading = styled(Heading)`
  font-size: 1.75rem;
  font-family: "Proxima-nova-condensed", Helvetica, Arial, sans-serif;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
`

export default () => (
  <Wrapper className='fav-wineries'>
    <Container>
      <VisitIcon />
      <NoWineriesHeading textAlign='center'>No wineries visited yet</NoWineriesHeading>
      <Paragraph textAlign='center'>
        When you find a winery you have visited, click the <VisitIcon /> visited icon to save it
      </Paragraph>
      <Button
        href='/wineries'
        color={colors.silverTree}
      >
        Start Exploring
      </Button>
    </Container>
  </Wrapper>
)
