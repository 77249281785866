import React, { useContext, useEffect, useRef } from 'react'
import styled from 'styled-components'
import GoogleMapReact from 'google-map-react'
import { colors } from '../common'
import MessageBox from '../MessageBox'
import Pin from './Pin'
import breakpoints from './breakpoints'
import { WineriesContext } from './WineriesContext'
import { DirectionsContext } from './DirectionsContext'
import { StartAddressContext } from './StartAddressContext'

const Container = styled.div`
  background-color: ${colors.daintree_40};
  width: 100%;
  height: 100%;
  max-height: 100vh;
  position: relative;
  order: 1;
  -ms-grid-column: 1;

  @media (min-width: ${breakpoints.columns}px) {
    order: 2;
    -ms-grid-column: 2;
  }
`

const MessageBoxContainer = styled.div`
  display: none;
  position: absolute;
  left: 1.25rem;
  right: auto;
  bottom: 1.25rem;

  @media (min-width: 1090px) {
    display: block;
  }
`
export function ItineraryMap ({ googleApiKey }) {
  const wineries = useContext(WineriesContext)
  const directions = useContext(DirectionsContext)
  const startAddress = useContext(StartAddressContext)
  const directionsRenderer = useRef(null)
  const map = useRef(null)

  useEffect(() => {
    if (map.current != null) {
      directionsRenderer.current = new window.google.maps.DirectionsRenderer({
        suppressMarkers: true,
        polylineOptions: {
          strokeColor: colors.roseVale
        }
      })
      directionsRenderer.current.setMap(map.current)
    }
  }, [directionsRenderer, map.current])

  useEffect(() => {
    if (directionsRenderer.current != null) {
      if (wineries.length > 0 && Object.keys(directions).length > 0) {
        directionsRenderer.current.setDirections(directions)
      } else {
        directionsRenderer.current.setDirections({ routes: [] })
      }
    }
  }, [wineries, directions, directionsRenderer.current])

  return (
    <Container>
      <GoogleMapReact
        onGoogleApiLoaded={({ map: gMap }) => {
          map.current = gMap
        }}
        defaultCenter={{ lat: 37.531399, lng: -77.476009 }}
        yesIWantToUseGoogleMapApiInternals
        bootstrapURLKeys={{
          key: googleApiKey
        }}
        defaultZoom={11}
      >
        {wineries.map((winery, index) => {
          return (
            <Pin
              onMap
              lat={winery.address.latitude}
              lng={winery.address.longitude}
              key={`map-${winery.id}`}
              text={index + 1}
            />
          )
        })}
        {startAddress &&
          <Pin
            onMap
            green
            lat={startAddress.address.latitude}
            lng={startAddress.address.longitude}
          />}
      </GoogleMapReact>
      <MessageBoxContainer>
        <MessageBox
          phoneIcon
          floatBreakpoint='0'
          heading='Use your itinerary on the go!'
        >
          <MessageBox.Text>
            <MessageBox.Link href='/app'>Download the App</MessageBox.Link> and use your itinerary on your next trip to a Virginia winery.
          </MessageBox.Text>
        </MessageBox>
      </MessageBoxContainer>
    </Container>
  )
}
