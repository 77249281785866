import React, { useContext } from 'react'
import EmptyContent from '../../EmptyContent'
import { metersToMiles, secondsToTime } from '../../../../helpers/conversions'
import { myItineraryModes } from '../../../../helpers/maps'
import Header from './Header'
import SearchBar from './SearchBar'
import Summary from './Summary'
import Container from './Container'
import WineryList from './WineryList'
import { WineriesContext } from '../../WineriesContext'
import { DirectionsContext } from '../../DirectionsContext'

const calculateTotalDistanceDuration = (directions) => {
  const total = { distance: 0, duration: 0 }
  if (directions.routes !== undefined) {
    const legs = directions.routes[0].legs
    for (let i = 0; i < legs.length; i++) {
      total.distance += legs[i].distance.value
      total.duration += legs[i].duration.value
    }
  }
  return total
}

function Panel (props) {
  const {
    mode,
    loggedIn,
    directionsURL
  } = props

  const wineries = useContext(WineriesContext)
  const directions = useContext(DirectionsContext)

  const total = calculateTotalDistanceDuration(directions)
  const humanizedTotalDistance = `${metersToMiles(total.distance)} mi`
  const humanizedTotalDuration = secondsToTime(total.duration)

  return (
    <Container active={mode === myItineraryModes.LIST}>
      <Header loggedIn={loggedIn} directionURL={directionsURL} />
      {!wineries.length && <EmptyContent />}
      {wineries.length > 0 && (
        <>
          <SearchBar />
          <WineryList />
        </>
      )}
      {wineries.length > 0 && (
        <Summary
          wineryCount={wineries.lenght}
          duration={humanizedTotalDuration}
          distance={humanizedTotalDistance}
        />
      )}
    </Container>
  )
};

export default Panel
