import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button } from '../common'
import Logo from './Logo'

const Wrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`

const Container = styled.div`
  width: 100%;
  max-width: 1190px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 auto;
  padding: 1rem;
`

const LogoContainer = styled.div`
  width: 130px;
  @media(min-width: 600px) {
    width: 170px;
  }
`

const Link = styled.a`
  display: inherit;
  margin-left: 1rem;
`

const Text = styled.p`
  color: #fff;
  font-weight: 500;
  font-size: 0.75rem;

  @media(min-width: 600px) {
    font-size: 1rem;
  }
`

const LoginButton = styled(Button)`
  border-color: rgba(255,255,255,0.4);
  opacity: 1;
  color: #fff;
  margin-left: 0.5rem;

  @media(min-width: 600px){
    margin-left: 1.25rem;
  }
`

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Navbar = (props) => (
  <Wrapper>
    <Container>
      <LogoContainer>
        <Link href='/'>
          <Logo />
        </Link>
      </LogoContainer>
      <ActionsContainer>
        {props.helperText &&
          <Text>{props.helperText}</Text>}
        {props.buttonText &&
          <LoginButton href={props.buttonPath}>{props.buttonText}</LoginButton>}
      </ActionsContainer>
    </Container>
  </Wrapper>
)

Navbar.propTypes = {
  helperText: PropTypes.string,
  buttonPath: PropTypes.string,
  buttontext: PropTypes.string
}

export default Navbar
