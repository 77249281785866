import React from 'react'

export default () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30' name='remove'>
    <g fillRule='evenodd'>
      <path d='M7.912 21.965c-3.88-3.88-3.88-10.192 0-14.071 3.88-3.88 10.192-3.88 14.071 0 3.88 3.879 3.88 10.192 0 14.071-3.879 3.88-10.192 3.88-14.071 0zM21.21 8.667c-3.453-3.454-9.072-3.454-12.525 0-3.454 3.453-3.454 9.072 0 12.525 3.453 3.453 9.072 3.453 12.525 0 3.454-3.453 3.454-9.072 0-12.525z' />
      <path d='M14.948 14.148L11.82 11.02l-.782.781 3.127 3.127-3.127 3.127.782.782 3.127-3.127 3.127 3.127.781-.782-3.127-3.127 3.127-3.127-.781-.781-3.127 3.127z' />
    </g>
  </svg>
)
