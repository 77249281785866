import axios from 'axios'
import { getCoordsFromWinery } from '../../helpers/maps'

function addStartAddressMutationQuery (startAddress, token) {
  const {
    latitude,
    longitude,
    street_address: strAddr,
    locality,
    state,
    postal_code: zip
  } = startAddress.address
  return {
    query: `mutation {
      addStartAddressToItinerary(input: {
        token: "${token}"
        start_address: {
          name: "${startAddress.name}"
          latitude: ${latitude}
          longitude: ${longitude}
          street_address: "${strAddr}"
          locality: "${locality}"
          postal_code: "${zip}"
          state: "${state}"
        }
      }) {
        itinerary {
          start_address {
            name
          }
        }
        errors
      }
    }`
  }
}

function removeWineryMutationQuery (wineryID, token) {
  return {
    query: `mutation {
        removeWineryFromItinerary(input: {
          token: "${token}",
          winery_id: ${wineryID}
        }) { errors }
      }`
  }
}

function removeStartAddressQuery (token) {
  return {
    query: `mutation {
      removeStartAddressFromItinerary(input: {
        token: "${token}"
      }) { errors }
    }`
  }
}

function reorderWineryMutationQuery (wineryID, position, token) {
  return {
    query: `mutation {
      reorderWineryInItinerary(input: {
        token: "${token}",
        winery_id: ${wineryID},
        position: ${position}
      }) { errors }
    }`
  }
}

export function getOrigin (startAddress, wineries) {
  if (startAddress) {
    return startAddress
  }
  if (wineries && wineries.length > 0) {
    return wineries[0]
  }
  return null
}

export function getDestination (wineries) {
  if (wineries.length > 0) {
    return wineries.slice(-1)[0]
  }
  return null
}

export function getWaypoints (startAddress, wineries) {
  if (startAddress) {
    return wineries.slice(0, -1)
  }
  return wineries.slice(1, -1)
}

export function updateStartAddressModel (formattedPlaceResult, token) {
  if (token) {
    axios.post('/graphql',
      addStartAddressMutationQuery(formattedPlaceResult, token))
      .then((response) => {
        if (response.data.data.addStartAddressToItinerary.errors !== null) {
          console.error(response.data.data.addStartAddressToItinerary.errors)
        }
      })
      .catch(error => console.error(error))
  }
}

export function removeStartAddress (token) {
  if (token) {
    axios.post('/graphql', removeStartAddressQuery(token))
      .then(response => {})
      .catch(error => { console.error(error) })
  }
}

export function removeWineryFromItinerary (wineryID, token) {
  if (token) {
    axios.post('/graphql', removeWineryMutationQuery(wineryID, token))
      .then((response) => {
        if (response.data.data.removeWineryFromItinerary.errors != null) {
          console.error(response.data.data.removeWineryFromItinerary.errors)
        }
      })
      .catch(error => { console.error(error) })
  }
}

export function reorderWineryInItinerary (wineryID, position, token) {
  if (token) {
    axios.post('/graphql',
      // the rails application indexes from 1, not zero
      reorderWineryMutationQuery(wineryID, position + 1, token))
      .then((response) => {
        if (response.data.data.reorderWineryInItinerary.errors !== null) {
          console.error(response.data.data.removeWineryFromItinerary.errors)
        }
      })
      .catch(error => { console.error(error) })
  }
}

export function getWaypointsCoordinates (waypoints) {
  return waypoints.map((winery) => {
    return { location: getCoordsFromWinery(winery) }
  })
}

export function generateDirectionsURL (wineries, startAddress, test) {
  const origin = getOrigin(startAddress, wineries)
  const destination = getDestination(wineries)
  const waypoints = getWaypoints(startAddress, wineries)
  if (origin && destination && !test) {
    const originCoords = getCoordsFromWinery(origin)
    const destinationCoords = getCoordsFromWinery(destination)
    const waypointsCoords = waypoints.map((winery) => {
      return getCoordsFromWinery(winery)
    })
    const baseURL = 'https://www.google.com/maps/dir/?api=1'
    return (
        `${baseURL}&origin=${originCoords}&destination=${destinationCoords}` +
        `&waypoints=${waypointsCoords.join('|')}`
    )
  }
  return ''
}
