import React from 'react'
import styled from 'styled-components'
import { colors } from '../common'

const Container = styled.div`
  background-color: ${colors.hintOfRed};
  border-radius: 50%;
  height: 85px;
  width: 85px;
  margin: 0 auto;
  display:  flex;
  justify-content: center;
  align-items: center;
`

export default () => (
  <Container>
    <svg width='43px' height='38px' viewBox='0 0 43 38' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <g id='Web---Favoriting' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Favorites---Index-Page---Empty' transform='translate(-698.000000, -370.000000)' fill={colors.roseVale}>
          <g id='Group-4' transform='translate(676.000000, 344.000000)'>
            <g id='Icon-Heart-/-purple' transform='translate(22.000000, 26.000000)'>
              <path d='M21.5,37.5288 L20.7576176,36.9233333 C4.4935,23.6562667 0.0885294118,18.9860667 0.0885294118,11.4 C0.0885294118,5.16293333 5.15494118,0.0886666667 11.3823529,0.0886666667 C16.5866176,0.0886666667 19.5283235,3.04886667 21.5,5.27566667 C23.4716765,3.04886667 26.4133824,0.0886666667 31.6176471,0.0886666667 C37.8450588,0.0886666667 42.9114706,5.16293333 42.9114706,11.4 C42.9114706,18.9860667 38.5065,23.6562667 22.2423824,36.9233333 L21.5,37.5288 L21.5,37.5288 Z M11.3823529,2.44466667 C6.45252941,2.44466667 2.44088235,6.46253333 2.44088235,11.4 C2.44088235,17.8992667 6.52461765,22.2439333 21.5,34.4888 C36.4753824,22.2439333 40.5591176,17.8992667 40.5591176,11.4 C40.5591176,6.46253333 36.5474706,2.44466667 31.6176471,2.44466667 C27.1026471,2.44466667 24.7465,5.13633333 22.8545,7.2998 L21.5,8.8236 L20.1455,7.2998 C18.2535,5.13633333 15.8973529,2.44466667 11.3823529,2.44466667 L11.3823529,2.44466667 Z' id='Fill-18' />
            </g>
          </g>
        </g>
      </g>
    </svg>
  </Container>
)
