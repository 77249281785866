import * as React from 'react'
import { createRoot } from 'react-dom/client'
import MessageBox from './components/MessageBox'
import FavoriteWineries from './components/FavoriteWineries'
import GovcupCta from './components/GovcupCta'
import Video from './components/Video'
import AppDownload from './components/AppDownload'
import ActionPillWithModal from './components/ActionPillWithModal'
import ItineraryPage from './components/ItineraryPage'

const components = {
  ActionPillWithModal,
  AppDownload,
  FavoriteWineries,
  GovcupCta,
  ItineraryPage,
  MessageBox,
  Video
}

function renderReactComponent (componentName) {
  const domNodes = document.querySelectorAll(`[data-react-component='${componentName}']`)

  domNodes.forEach(domNode => {
    const props = domNode?.dataset?.componentProps

    if (domNode != null && props != null) {
      const parsedProps = JSON.parse(props)
      const root = createRoot(domNode)
      const Component = components[componentName]
      root.render(<Component {...parsedProps} />)
    }
  })
}

function renderComponents () {
  Object.keys(components).forEach(comp => {
    renderReactComponent(comp)
  })
}

renderComponents()
