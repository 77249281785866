import * as React from 'react'

const ItineraryIcon = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={76} height={76} {...props}>
    <g fill='none' fillRule='evenodd'>
      <circle cx={38} cy={38} r={38} fill='#FFF' />
      <path
        fill='#A4343A'
        d='M45.59 23.517a.954.954 0 0 0-.965.964c0 .541.424.965.965.965.54 0 .964-.424.964-.965a.954.954 0 0 0-.964-.964zm0 3.857a2.895 2.895 0 0 1-2.893-2.893 2.895 2.895 0 0 1 2.893-2.892 2.895 2.895 0 0 1 2.892 2.892 2.895 2.895 0 0 1-2.892 2.893zm-.015-8.196c-2.916 0-5.288 2.467-5.288 5.5 0 .703.22 1.66.436 2.241l4.867 10.253 4.882-10.29c.201-.542.42-1.497.42-2.205 0-3.032-2.385-5.499-5.317-5.499zM19.572 55.511l11.57-3.296V23.357l-11.57 3.296v28.858zm25.068 0V39.67l-5.708-12.03c-.27-.718-.541-1.78-.57-2.784l-5.292-1.5v28.858l11.57 3.296zm1.929 0 11.57-3.296V23.357l-5.321 1.515c-.032 1.002-.302 2.055-.555 2.731-1.053 2.214-2.085 4.394-3.118 6.575a3503.24 3503.24 0 0 1-2.576 5.432v15.9zm-.964 2.239a.972.972 0 0 1-.27-.038l-13.23-3.773-13.23 3.773a.899.899 0 0 1-.248.035 1 1 0 0 1-.707-.303.934.934 0 0 1-.277-.67V25.928a.976.976 0 0 1 .693-.915l13.522-3.863.037-.008c.06-.011.17-.03.26-.03.117 0 .21.026.285.062l6.12 1.75c.773-3.319 3.644-5.674 7.015-5.674 3.373 0 6.25 2.356 7.03 5.676l6.231-1.77a.875.875 0 0 1 .248-.036c.522 0 .972.43.983.94v30.865a.98.98 0 0 1-.692.93l-13.502 3.857a.976.976 0 0 1-.268.038z'
      />
    </g>
  </svg>
)
export default ItineraryIcon
