import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import colors from '../common/colors'

const Divider = styled.div`
  background-color: #fff;
  width: 1px;
  height: 44px;

  ${props => props.mini && css`
    height: 28px;
  `}

  ${props => props.light && css`
    background-color: ${colors.daintree_80};
  `}
`

Divider.propTypes = {
  mini: PropTypes.bool,
  light: PropTypes.bool
}

export default Divider
