import React from 'react'
import styled, { keyframes } from 'styled-components'

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const SpinningSVG = styled.svg`
  animation: ${spin} 1s linear infinite;
`

export default () => (
  <SpinningSVG xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'>
    <g fill='#49a9c5' fillRule='evenodd'>
      <path d='M2.772 8.527c-.31 0-.574.105-.791.316-.218.21-.326.466-.326.767 0 .3.108.556.326.767.217.21.48.316.791.316a1.1 1.1 0 0 0 .788-.316c.22-.21.33-.466.33-.767s-.11-.556-.33-.767a1.1 1.1 0 0 0-.788-.316zM2.524 6.24c0-.33-.122-.613-.365-.85a1.215 1.215 0 0 0-.877-.353c-.341 0-.633.118-.877.354a1.141 1.141 0 0 0-.364.85c0 .33.121.614.364.85.244.235.536.353.877.353.342 0 .634-.118.877-.354a1.14 1.14 0 0 0 .365-.85zM9.724 3.473c.171 0 .317-.059.439-.177a.571.571 0 0 0 .182-.425.57.57 0 0 0-.182-.424.607.607 0 0 0-.439-.177.608.608 0 0 0-.438.177.57.57 0 0 0-.182.424c0 .166.06.308.182.425a.607.607 0 0 0 .438.177zM2.772 1.548c-.378 0-.7.129-.966.387s-.4.57-.4.936c0 .367.134.679.4.937.267.258.589.387.966.387.378 0 .7-.129.966-.387.267-.258.4-.57.4-.937 0-.366-.133-.678-.4-.936a1.337 1.337 0 0 0-.966-.387zM11.742 5.73a.73.73 0 0 0-.528-.211.73.73 0 0 0-.527.21.686.686 0 0 0-.217.512c0 .2.072.37.217.511.145.14.32.21.527.21a.73.73 0 0 0 .528-.21.685.685 0 0 0 .217-.511c0-.2-.072-.371-.217-.512zM9.724 8.768a.85.85 0 0 0-.613.248.799.799 0 0 0-.256.594c0 .23.086.429.256.594a.85.85 0 0 0 .613.248.85.85 0 0 0 .614-.248.798.798 0 0 0 .256-.594.799.799 0 0 0-.256-.594.85.85 0 0 0-.614-.248zM6.248 10.091a.973.973 0 0 0-.702.282.914.914 0 0 0-.291.68c0 .267.097.493.29.681a.973.973 0 0 0 .703.283.972.972 0 0 0 .702-.283.913.913 0 0 0 .292-.68.913.913 0 0 0-.292-.68.972.972 0 0 0-.702-.283zM6.248-.017c-.414 0-.765.14-1.055.422-.29.28-.435.621-.435 1.022 0 .402.145.742.435 1.023.29.28.641.421 1.055.421.414 0 .766-.14 1.056-.42.29-.281.434-.622.434-1.024 0-.4-.145-.742-.434-1.022a1.46 1.46 0 0 0-1.056-.422z' />
    </g>
  </SpinningSVG>
)
