import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { colors, fonts } from '../common'
import breakpoints from './breakpoints'
import { colorToHex } from '../../helpers/conversions'
import Pin from './Pin'
import RemoveIcon from './assets/RemoveIcon'
import HamburgerIcon from './assets/HamburgerIcon'
import { WineriesDispatchContext } from './WineriesContext'
import { UserContext } from './UserContext'
import { removeWineryFromItinerary } from './helpers'

const Container = styled.div`
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 1rem 1.25rem;
  position: relative;
  border-top: 1px solid ${colors.quillGrey};
  cursor: grab;
`

const DistanceTime = styled.div`
  font-family: ${fonts.markMedium};
  background-color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.7px;
  font-size: 0.75rem;
  color: ${colors.daintree_80};
  display: inline-block;
  padding: 0.5rem;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  transition: 0.15s ease-in;

  ${props => props.sorting && css`
    opacity: 0;
  `}
`

const Thumbnail = styled.a`
  background-color: ${colors.roseVale};
  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: block;
  width: 100px;
  height: 100px;
  margin-right: 1rem;
  border-radius: 5px;
  position: relative;

  @media (min-width: ${breakpoints.thumbnail}px) {
    ${'' /* width: 192px;
    height: 142px; */}
    width: 150px;
    height: 1180x;
    margin-right: 1.5rem;
  }
`

Thumbnail.propTypes = {
  src: PropTypes.string
}

const ThumbnailIndex = styled.div`
  background-color: ${colors.roseVale};
  font-weight: 600;
  font-size: 0.9375rem;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate3d(-50%, -50%, 0);

  @media (min-width: ${breakpoints.columns}px) {
    display: none;
  }
`

const Name = styled.a`
  font-family: ${fonts.dinMedium};
  text-decoration: none;
  font-weight: normal;
  font-size: 1.125rem;
  color: ${colors.daintree};
  display: block;
  margin: 0;
  cursor: pointer;

  @media (min-width: ${breakpoints.thumbnail}px) {
    font-size: 1.25rem;
  }
`

const Address = styled.address`
  font-family: ${fonts.markMedium};
  line-height: 1.5;
  letter-spacing: 0.7px;
  text-transform: uppercase;
  font-size: 0.75rem;
  font-style: normal;
  color: ${colors.daintree_80};
  margin: 0.25rem 0;
`

const Region = styled.a`
  text-decoration: none;
  font-weight: 600;
  font-size: 0.8125rem;
  color: ${colors.viking};
  display: inline-flex;
  align-items: center;
  transition: 0.15s ease-in;
  cursor: pointer;

  &:hover {
    color: ${colors.vikingDark};
  }

  &:before {
    content: '';
    background-color: ${props => props.color};
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 0.3125rem;
  }
`

Region.propTypes = {
  color: PropTypes.string
}

const Controls = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
`

const RemoveAction = styled.a`
  opacity: 0.3;
  cursor: pointer;
  transition: 0.15s ease-in;
  z-index: 5;
  svg {
    pointer-events: none;
  }

  &:hover {
    opacity: 1;
  }
`

const MoveAction = styled.div`
  opacity: 0.3;
`

const PinContainer = styled.div`
  display: none;
  margin: 1rem 0;

  @media (min-width: ${breakpoints.columns}px) {
    display: block;
  }
`

function Winery ({ winery, distanceDuration, sorting, wineryIndex }) {
  const token = useContext(UserContext)
  const wineriesDispatch = useContext(WineriesDispatchContext)

  const handleRemove = () => {
    wineriesDispatch({ type: 'remove', payload: { wineryId: winery.id } })
    removeWineryFromItinerary(winery.id, token)
  }

  return (
    <Container>
      {distanceDuration &&
        <DistanceTime sorting={sorting}>
          {distanceDuration.distance}
          {' / '}
          {distanceDuration.duration}
        </DistanceTime>}
      <Thumbnail
        href={winery.url}
        src={winery.banner_image ? winery.banner_image.replace(/\(/g, '%28').replace(/\)/g, '%29') : ''}
      >
        <ThumbnailIndex>
          {wineryIndex}
        </ThumbnailIndex>
      </Thumbnail>
      <div>
        <Name href={winery.url}>{winery.name}</Name>
        <Address>
          {winery.address.street_address}
          <br />
          {winery.address.city}, {winery.address.state}
        </Address>
        <Region
          color={colorToHex(winery.region.accent_color)}
          href={winery.region.url}
        >
          {winery.region.name}
        </Region>
      </div>
      <Controls>
        <RemoveAction
          onClick={handleRemove}
        >
          <RemoveIcon />
        </RemoveAction>
        <PinContainer>
          <Pin text={wineryIndex} />
        </PinContainer>
        <MoveAction>
          <HamburgerIcon />
        </MoveAction>
      </Controls>
    </Container>
  )
}

Winery.propTypes = {
  winery: PropTypes.object,
  wineryIndex: PropTypes.number,
  index: PropTypes.number,
  distanceDuration: PropTypes.object
}

export default Winery
