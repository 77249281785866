import * as React from 'react'

const AppStoreButton = (props) => (
  <svg
    width='142px'
    height='48px'
    viewBox='0 0 142 48'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <defs />
    <g
      id='Web---Landing-Page'
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
    >
      <g
        id='9.-Mobile-App-Landing-Page'
        transform='translate(-135.000000, -356.000000)'
      >
        <g id='Group-5' transform='translate(135.000000, 356.000000)'>
          <g id='App-Store'>
            <g>
              <rect
                id='background'
                fill='#000000'
                x='0'
                y='0'
                width='142'
                height='48'
                rx='7.68'
              />
              <g
                id='icon'
                transform='translate(12.594448, 8.448000)'
                fill='#FFFFFF'
              >
                <path
                  d='M18.4918001,14.4532061 C18.460861,11.0197304 21.3072622,9.34933325 21.43742,9.27156604 C19.8253839,6.92470071 17.3267814,6.60404412 16.4487498,6.57847681 C14.3502224,6.35795883 12.3146401,7.83233995 11.2456394,7.83233995 C10.1553013,7.83233995 8.50912547,6.5997829 6.73492562,6.63600325 C4.4518302,6.67115829 2.31596243,7.99107029 1.14454245,10.0407157 C-1.27297821,14.2199044 0.530027046,20.3613836 2.84619534,23.7394635 C4.00481292,25.3949464 5.35866717,27.2411187 7.13073328,27.1761351 C8.86439219,27.105825 9.51198046,26.0724799 11.6041067,26.0724799 C13.6770293,26.0724799 14.2851435,27.1761351 16.0924162,27.1335229 C17.9530323,27.105825 19.1244523,25.4727136 20.2425289,23.8023165 C21.581447,21.9082054 22.119148,20.0385964 22.1404853,19.942719 C22.0978107,19.9288701 18.5280736,18.5684765 18.4918001,14.4532061'
                  id='Fill-2'
                />
                <path
                  d='M15.0778257,4.35625208 C16.0102675,3.1918745 16.6482539,1.60776703 16.471154,0.000222870023 C15.1215672,0.0598799098 13.4337836,0.932364117 12.4618678,2.0711744 C11.601973,3.07469103 10.8338287,4.71952084 11.032266,6.26634266 C12.548284,6.37926491 14.104843,5.50251949 15.0778257,4.35625208'
                  id='Fill-3'
                />
              </g>
              <g
                transform='translate(44.224780, 21.024000)'
                fill='#FFFFFF'
              >
                <path
                  d='M11.9723174,14.3625723 L9.54946239,14.3625723 L8.22227982,10.1982978 L3.60914683,10.1982978 L2.34490942,14.3625723 L-0.0139335577,14.3625723 L4.55759161,0.18550287 L7.38052174,0.18550287 L11.9723174,14.3625723 L11.9723174,14.3625723 Z M7.82220468,8.4511988 L6.62197929,4.75033172 C6.49502211,4.3710834 6.2560439,3.48048902 5.90611152,2.07854858 L5.86343684,2.07854858 C5.72261039,2.6815108 5.49750145,3.57210518 5.18917689,4.75033172 L4.00922196,8.4511988 L7.82220468,8.4511988 L7.82220468,8.4511988 Z'
                  id='Fill-4'
                />
                <path
                  d='M23.7057208,9.12553641 C23.7057208,10.864113 23.2330987,12.2383555 22.2867877,13.2471987 C21.4396953,14.1452502 20.3866975,14.5937433 19.1309951,14.5937433 C17.7750071,14.5937433 16.7998906,14.1111605 16.2077794,13.1427989 L16.2077794,18.5066059 L13.933219,18.5066059 L13.933219,7.49988207 C13.933219,6.40794518 13.9044135,5.28831038 13.8489365,4.13991237 L15.8493121,4.13991237 L15.9762693,5.76130548 L16.018944,5.76130548 C16.7774864,4.53940147 17.9286359,3.92898212 19.4734594,3.92898212 C20.6811528,3.92898212 21.6893422,4.40517313 22.4958936,5.35862047 C23.3013782,6.31419841 23.7057208,7.56912685 23.7057208,9.12553641 L23.7057208,9.12553641 Z M21.3884857,9.20863014 C21.3884857,8.21363594 21.1644436,7.39335165 20.7142257,6.74777725 C20.2224,6.07557025 19.5620093,5.73786879 18.7341205,5.73786879 C18.1729485,5.73786879 17.6629861,5.92536234 17.2074338,6.29608823 C16.7508148,6.66787943 16.452092,7.15472349 16.3123324,7.75875101 C16.2419192,8.03892604 16.2067126,8.26796646 16.2067126,8.44906819 L16.2067126,10.1556856 C16.2067126,10.8971374 16.4350221,11.5246017 16.8916412,12.0380783 C17.3482603,12.5494244 17.9414383,12.8061627 18.6711754,12.8061627 C19.5278696,12.8061627 20.1946615,12.4748531 20.671551,11.8175603 C21.1495075,11.158137 21.3884857,10.2888487 21.3884857,9.20863014 L21.3884857,9.20863014 Z'
                  id='Fill-5'
                />
                <path
                  d='M35.4807321,9.12553641 C35.4807321,10.864113 35.00811,12.2383555 34.061799,13.2471987 C33.2136397,14.1452502 32.1617088,14.5937433 30.9049395,14.5937433 C29.5500184,14.5937433 28.5749019,14.1111605 27.9817238,13.1427989 L27.9817238,18.5066059 L25.7071634,18.5066059 L25.7071634,7.49988207 C25.7071634,6.40794518 25.678358,5.28831038 25.6228809,4.13991237 L27.6232565,4.13991237 L27.7502137,5.76130548 L27.7928884,5.76130548 C28.550364,4.53940147 29.7015135,3.92898212 31.2474038,3.92898212 C32.4540304,3.92898212 33.4632866,4.40517313 34.2709049,5.35862047 C35.0753226,6.31419841 35.4807321,7.56912685 35.4807321,9.12553641 L35.4807321,9.12553641 Z M33.1624301,9.20863014 C33.1624301,8.21363594 32.9373211,7.39335165 32.4871033,6.74777725 C31.9963444,6.07557025 31.3370206,5.73786879 30.5091318,5.73786879 C29.9479598,5.73786879 29.4379973,5.92536234 28.9803114,6.29608823 C28.5247592,6.66787943 28.2260364,7.15472349 28.0862768,7.75875101 C28.0169305,8.03892604 27.9795901,8.26796646 27.9795901,8.44906819 L27.9795901,10.1556856 C27.9795901,10.8971374 28.2089665,11.5246017 28.6634519,12.0380783 C29.120071,12.5483591 29.713249,12.8061627 30.4451198,12.8061627 C31.3028809,12.8061627 31.9696728,12.4748531 32.4454954,11.8175603 C32.9234519,11.158137 33.1624301,10.2888487 33.1624301,9.20863014 L33.1624301,9.20863014 Z'
                  id='Fill-6'
                />
                <path
                  d='M48.6458711,10.3868567 C48.6458711,11.5927811 48.2244586,12.5739264 47.3837674,13.3313577 C46.4598606,14.1580338 45.1700184,14.5724372 43.5163745,14.5724372 C41.9886209,14.5724372 40.7638576,14.2784132 39.8399507,13.6893 L40.3659162,11.7962542 C41.3634368,12.3853675 42.4591093,12.6804568 43.6518666,12.6804568 C44.5096277,12.6804568 45.1764196,12.4865714 45.6522422,12.1019966 C46.1301987,11.7152911 46.3702438,11.2007492 46.3702438,10.5573054 C46.3702438,9.98097575 46.1718065,9.4973276 45.7791994,9.10423032 C45.3855255,8.71326365 44.7347366,8.34679898 43.8214984,8.01229343 C41.3356983,7.08654401 40.0917314,5.73147696 40.0917314,3.95135351 C40.0917314,2.78804123 40.5312806,1.8356592 41.4071784,1.09101151 C42.2841431,0.347429121 43.4470281,-0.0243620736 44.8936998,-0.0243620736 C46.1856758,-0.0243620736 47.2621446,0.20041713 48.1167051,0.648910233 L47.5459312,2.50040908 C46.7415135,2.06576493 45.8336096,1.84844285 44.8179522,1.84844285 C44.0156682,1.84844285 43.3851498,2.04552415 42.9359988,2.43755612 C42.557261,2.78804123 42.3641581,3.21522825 42.3641581,3.72231309 C42.3641581,4.28159784 42.5828658,4.7460705 43.0192145,5.10933926 C43.3968854,5.44597542 44.0860815,5.81137479 45.0825353,6.20340676 C46.3051649,6.69557734 47.2024,7.26871104 47.7785082,7.92706909 C48.357817,8.58542714 48.6458711,9.40677674 48.6458711,10.3868567'
                  id='Fill-7'
                />
                <path
                  d='M56.1854203,5.84439922 L53.6782828,5.84439922 L53.6782828,10.8076519 C53.6782828,12.0700374 54.1199658,12.6996323 55.0054654,12.6996323 C55.4119417,12.6996323 55.7490717,12.6655425 56.0157885,12.5941672 L56.0787336,14.3188948 C55.6306495,14.4861476 55.040672,14.5703066 54.310935,14.5703066 C53.4115661,14.5703066 52.7106344,14.2954581 52.2028057,13.748957 C51.6971108,13.2013906 51.4442633,12.2841636 51.4442633,10.9930148 L51.4442633,5.840138 L49.9517163,5.840138 L49.9517163,4.13565115 L51.4442633,4.13565115 L51.4442633,2.26391153 L53.6782828,1.59063922 L53.6782828,4.13565115 L56.1854203,4.13565115 L56.1854203,5.84439922'
                  id='Fill-8'
                />
                <path
                  d='M67.4750071,9.16708328 C67.4750071,10.7384071 67.0258561,12.0284906 66.1264872,13.0373337 C65.1865773,14.0749401 63.9362092,14.5937433 62.3775165,14.5937433 C60.873234,14.5937433 59.6772761,14.0962462 58.7853752,13.101252 C57.8934744,12.1062578 57.447524,10.8513293 57.447524,9.33753196 C57.447524,7.75342449 57.9094774,6.45588388 58.826983,5.44704072 C59.7466224,4.43819757 60.9863219,3.93217803 62.5450146,3.93217803 C64.0482302,3.93217803 65.25379,4.43180574 66.168095,5.42573464 C67.0407922,6.39196562 67.4750071,7.63730633 67.4750071,9.16708328 L67.4750071,9.16708328 Z M65.117231,9.21928319 C65.117231,8.28288072 64.9145262,7.47964129 64.5080499,6.80743429 C64.0311604,5.99567243 63.3472986,5.5908568 62.4639327,5.5908568 C61.5538951,5.5908568 60.8529635,5.99567243 60.3782077,6.80743429 C59.9695976,7.47964129 59.7679598,8.29672968 59.7679598,9.26189536 C59.7679598,10.1993631 59.9695976,11.0047332 60.3782077,11.6737443 C60.8700334,12.4865714 61.5560289,12.8924524 62.445796,12.8924524 C63.3142257,12.8924524 63.9959537,12.4769837 64.4899132,11.6545688 C64.9070582,10.9663822 65.117231,10.1578163 65.117231,9.21928319 L65.117231,9.21928319 Z'
                  id='Fill-9'
                />
                <path
                  d='M74.8715961,6.1373579 C74.6464872,6.09581103 74.4053752,6.07557025 74.1546615,6.07557025 C73.3545112,6.07557025 72.7357284,6.37492075 72.3004466,6.98001358 C71.9217088,7.51266573 71.7328734,8.18593803 71.7328734,8.9987652 L71.7328734,14.3625723 L69.4583129,14.3625723 L69.4583129,7.35926191 C69.4583129,6.18210068 69.4359087,5.10720866 69.3921671,4.14097767 L71.3733392,4.14097767 L71.4565548,6.09687633 L71.5195,6.09687633 C71.7606119,5.42466933 72.1382828,4.88242945 72.6567802,4.47761382 C73.1646089,4.11114915 73.7108448,3.92898212 74.3008223,3.92898212 C74.5109951,3.92898212 74.7008974,3.94496168 74.8694624,3.97052899 L74.8715961,6.1373579'
                  id='Fill-10'
                />
                <path
                  d='M85.0441731,8.76759417 C85.0441731,9.17454041 85.0175015,9.51756838 84.9609575,9.79774341 L78.1383429,9.79774341 C78.1628809,10.8076519 78.4946765,11.5810628 79.1273287,12.1137149 C79.70237,12.5888406 80.4470432,12.8274688 81.3592145,12.8274688 C82.3695375,12.8274688 83.2913106,12.6676731 84.1202663,12.3438206 L84.4765999,13.920471 C83.5078846,14.3412662 82.3631363,14.5521964 81.0444887,14.5521964 C79.4569906,14.5521964 78.2108899,14.0855931 77.3040529,13.1545172 C76.4004166,12.222376 75.9459312,10.9685128 75.9459312,9.40038491 C75.9459312,7.85995492 76.3662768,6.57626326 77.2112355,5.55250585 C78.0946014,4.45950365 79.2884256,3.91300255 80.7905743,3.91300255 C82.2660514,3.91300255 83.3830612,4.45950365 84.1405368,5.55250585 C84.7422498,6.41859823 85.0441731,7.49242494 85.0441731,8.76759417 L85.0441731,8.76759417 Z M82.8752325,8.1784809 C82.8912355,7.50414329 82.7418741,6.92461776 82.4335495,6.43244718 C82.0398756,5.79965644 81.4338951,5.48432637 80.6220093,5.48432637 C79.8773362,5.48432637 79.2724226,5.79219931 78.811536,6.41007579 C78.4327982,6.90118107 78.2076893,7.49242494 78.1383429,8.17635029 L82.8752325,8.1784809 L82.8752325,8.1784809 Z'
                  id='Fill-11'
                />
              </g>
              <g
                id='Available-on-the'
                transform='translate(45.090047, 8.832000)'
                fill='#FFFFFF'
              >
                <path
                  d='M5.86019796,7.30345857 L4.6685075,7.30345857 L4.01665175,5.25700905 L1.74955934,5.25700905 L1.12757587,7.30345857 L-0.03210858,7.30345857 L2.21471336,0.335303261 L3.60164048,0.335303261 L5.86019796,7.30345857 L5.86019796,7.30345857 Z M3.82034822,4.39837379 L3.23037076,2.57883408 C3.16849247,2.39240583 3.0543377,1.95882699 2.87830464,1.2663792 L2.8569673,1.2663792 C2.78442035,1.56572971 2.68093424,2.00037385 2.52517166,2.57883408 L1.94586287,4.39837379 L3.82034822,4.39837379 L3.82034822,4.39837379 Z'
                  id='Fill-12'
                />
                <path
                  d='M11.4783197,2.27948358 L9.57289518,7.30345857 L8.48575769,7.30345857 L6.64221148,2.27948358 L7.84350374,2.27948358 L8.70339856,4.93635246 C8.84849247,5.38058434 8.97224905,5.80457545 9.06506648,6.20726047 L9.09600562,6.20726047 C9.17922125,5.84612231 9.30297782,5.42213121 9.45767354,4.93635246 L10.3068997,2.27948358 L11.4783197,2.27948358'
                  id='Fill-13'
                />
                <path
                  d='M15.5345481,7.30345857 L15.4513324,6.72499835 L15.4203933,6.72499835 C15.0789959,7.19053632 14.5818358,7.41744613 13.9502505,7.41744613 C13.0498148,7.41744613 12.4075608,6.786786 12.4075608,5.93880379 C12.4075608,4.6987896 13.4840297,4.05747642 15.3478463,4.05747642 L15.3478463,3.96479495 C15.3478463,3.30324099 14.9957802,2.97193136 14.3023167,2.97193136 C13.8051566,2.97193136 13.3698749,3.09657196 12.9879365,3.34478786 L12.7606938,2.61079321 C13.2269147,2.32103044 13.8062235,2.17614906 14.4900853,2.17614906 C15.8044654,2.17614906 16.4680567,2.86859684 16.4680567,4.25455772 L16.4680567,6.10499125 C16.4680567,6.61207609 16.489394,7.00517337 16.5406037,7.30452388 L15.5345481,7.30345857 L15.5345481,7.30345857 Z M15.3787855,4.80105881 C14.1358854,4.80105881 13.5149688,5.10147462 13.5149688,5.81416319 C13.5149688,6.34148881 13.8360958,6.59929244 14.2809793,6.59929244 C14.8506863,6.59929244 15.3787855,6.1657136 15.3787855,5.57660033 L15.3787855,4.80105881 L15.3787855,4.80105881 Z'
                  id='Fill-14'
                />
                <path
                  d='M18.9581243,1.45174215 C18.5857877,1.45174215 18.2955999,1.16197938 18.2955999,0.779535147 C18.2955999,0.397090909 18.5953895,0.117981187 18.9794617,0.117981187 C19.3624669,0.117981187 19.6729252,0.397090909 19.6622566,0.779535147 C19.6622566,1.18328547 19.3731356,1.45174215 18.9581243,1.45174215 L18.9581243,1.45174215 Z M18.4204234,2.27948358 L19.5385,2.27948358 L19.5385,7.30345857 L18.4204234,7.30345857 L18.4204234,2.27948358 Z'
                  id='Fill-15'
                />
                <polygon
                  id='Fill-16'
                  points='21.5250064 -0.0258348911 22.643083 -0.0258348911 22.643083 7.30345857 21.5250064 7.30345857'
                />
                <path
                  d='M27.3949087,7.30345857 L27.3106262,6.72499835 L27.2807539,6.72499835 C26.9393565,7.19053632 26.4421965,7.41744613 25.8106112,7.41744613 C24.9101754,7.41744613 24.2679215,6.786786 24.2679215,5.93880379 C24.2679215,4.6987896 25.3443903,4.05747642 27.208207,4.05747642 L27.208207,3.96479495 C27.208207,3.30324099 26.8561409,2.97193136 26.1626773,2.97193136 C25.6655173,2.97193136 25.2302355,3.09657196 24.8482971,3.34478786 L24.6199876,2.6097279 C25.0862085,2.31996514 25.6655173,2.17508376 26.349379,2.17508376 C27.6637592,2.17508376 28.3284173,2.86753154 28.3284173,4.25349241 L28.3284173,6.10392595 C28.3284173,6.61101079 28.3497547,7.00410807 28.3988306,7.30345857 L27.3949087,7.30345857 L27.3949087,7.30345857 Z M27.2391461,4.80105881 C25.996246,4.80105881 25.3753294,5.10147462 25.3753294,5.81416319 C25.3753294,6.34148881 25.6964564,6.59929244 26.14134,6.59929244 C26.7110469,6.59929244 27.2391461,6.1657136 27.2391461,5.57660033 L27.2391461,4.80105881 L27.2391461,4.80105881 Z'
                  id='Fill-17'
                />
                <path
                  d='M32.8572678,7.41744613 C32.1435338,7.41744613 31.6165015,7.11703032 31.2740372,6.52791705 L31.2526998,6.52791705 L31.1886878,7.30345857 L30.2370424,7.30345857 C30.2669147,6.90077355 30.2775834,6.44482332 30.2775834,5.94945683 L30.2775834,-0.0258348911 L31.3977937,-0.0258348911 L31.3977937,3.01454353 L31.4180642,3.01454353 C31.7498599,2.45632409 32.2875608,2.17721437 33.0226322,2.17721437 C34.2345931,2.17721437 35.0838193,3.21162482 35.0838193,4.72009569 C35.0838193,6.27970116 34.1407089,7.41744613 32.8572678,7.41744613 L32.8572678,7.41744613 Z M32.6300252,3.05395979 C31.9867043,3.05395979 31.3977937,3.61217923 31.3977937,4.38772075 L31.3977937,5.26659678 C31.3977937,5.95904457 31.9269598,6.52791705 32.6097547,6.52791705 C33.4472453,6.52791705 33.9465391,5.84612231 33.9465391,4.76057725 C33.9454722,3.74747288 33.4269748,3.05395979 32.6300252,3.05395979 L32.6300252,3.05395979 Z'
                  id='Fill-18'
                />
                <polygon
                  id='Fill-19'
                  points='36.73853 -0.0258348911 37.8555398 -0.0258348911 37.8555398 7.30345857 36.73853 7.30345857'
                />
                <path
                  d='M43.9409493,5.05992775 L40.5845856,5.05992775 C40.6069898,6.01124448 41.2364414,6.54815784 42.1678163,6.54815784 C42.6660432,6.54815784 43.1215954,6.4650641 43.5248711,6.31059498 L43.6987705,7.0861365 C43.2229478,7.29280553 42.6639095,7.39614005 42.0120537,7.39614005 C40.437358,7.39614005 39.5059831,6.40327645 39.5059831,4.86391177 C39.5059831,3.32348177 40.4597622,2.16549602 41.8872303,2.16549602 C43.1696044,2.16549602 43.9793565,3.11681274 43.9793565,4.55390822 C43.9825571,4.74992421 43.9729553,4.92569941 43.9409493,5.05992775 L43.9409493,5.05992775 Z M42.9167569,4.26414545 C42.9167569,3.48860394 42.5220161,2.94103754 41.8072152,2.94103754 C41.1649613,2.94103754 40.6592663,3.49925698 40.5845856,4.26414545 L42.9167569,4.26414545 L42.9167569,4.26414545 Z'
                  id='Fill-20'
                />
                <path
                  d='M50.7176886,7.41744613 C49.2475458,7.41744613 48.2948336,6.32124802 48.2948336,4.83301794 C48.2948336,3.28193491 49.2678163,2.17614906 50.8019711,2.17614906 C52.2497096,2.17614906 53.2248261,3.22014726 53.2248261,4.75098951 C53.2248261,6.32124802 52.2198373,7.41744613 50.7176886,7.41744613 L50.7176886,7.41744613 Z M50.7603632,2.99217214 C49.9527449,2.99217214 49.4353144,3.74640757 49.4353144,4.80105881 C49.4353144,5.83546927 49.9634136,6.5897047 50.7496946,6.5897047 C51.5359756,6.5897047 52.0640747,5.78326936 52.0640747,4.77975273 C52.0640747,3.75706062 51.5466442,2.99217214 50.7603632,2.99217214 L50.7603632,2.99217214 Z'
                  id='Fill-21'
                />
                <path
                  d='M59.2355548,7.30345857 L58.1196119,7.30345857 L58.1196119,4.41861458 C58.1196119,3.5301508 57.7771476,3.08485361 57.1039545,3.08485361 C56.4414301,3.08485361 55.984811,3.6537261 55.984811,4.31528006 L55.984811,7.30345857 L54.8688681,7.30345857 L54.8688681,3.71551375 C54.8688681,3.27128186 54.8560657,2.79509085 54.8261934,2.27841827 L55.8098448,2.27841827 L55.8621213,3.05395979 L55.8941273,3.05395979 C56.1917832,2.51704643 56.8041649,2.17508376 57.4880267,2.17508376 C58.5420913,2.17508376 59.2355548,2.9815191 59.2355548,4.29397397 L59.2355548,7.30345857'
                  id='Fill-22'
                />
                <path
                  d='M66.551062,3.11681274 L65.3209643,3.11681274 L65.3209643,5.55635955 C65.3209643,6.17743195 65.5375383,6.48743549 65.9717532,6.48743549 C66.1680567,6.48743549 66.3355548,6.46612941 66.4689132,6.43523558 L66.4998524,7.28321779 C66.2822115,7.36631153 65.9909568,7.40785839 65.6314226,7.40785839 C64.7491236,7.40785839 64.224225,6.92207964 64.224225,5.65010633 L64.224225,3.11681274 L63.4912874,3.11681274 L63.4912874,2.27948358 L64.224225,2.27948358 L64.224225,1.35906068 L65.3209643,1.02775104 L65.3209643,2.27841827 L66.551062,2.27841827 L66.551062,3.11681274'
                  id='Fill-23'
                />
                <path
                  d='M72.4721739,7.30345857 L71.3530304,7.30345857 L71.3530304,4.43992066 C71.3530304,3.54080385 71.0105661,3.08591892 70.3384399,3.08591892 C69.7591311,3.08591892 69.2214301,3.4790162 69.2214301,4.2747985 L69.2214301,7.30345857 L68.1022866,7.30345857 L68.1022866,-0.0258348911 L69.2214301,-0.0258348911 L69.2214301,2.99217214 L69.2427675,2.99217214 C69.5937667,2.44460574 70.1026623,2.17508376 70.754518,2.17508376 C71.8203182,2.17508376 72.4721739,3.00175988 72.4721739,4.31528006 L72.4721739,7.30345857'
                  id='Fill-24'
                />
                <path
                  d='M78.1852468,5.05992775 L74.82995,5.05992775 C74.8512874,6.01124448 75.4807389,6.54815784 76.4131807,6.54815784 C76.9124744,6.54815784 77.3669598,6.4650641 77.7681018,6.31059498 L77.943068,7.0861365 C77.4683122,7.29280553 76.908207,7.39614005 76.2552844,7.39614005 C74.6816555,7.39614005 73.7502806,6.40327645 73.7502806,4.86391177 C73.7502806,3.32348177 74.7029928,2.16549602 76.1304609,2.16549602 C77.4139019,2.16549602 78.2225871,3.11681274 78.2225871,4.55390822 C78.2268546,4.74992421 78.2161859,4.92569941 78.1852468,5.05992775 L78.1852468,5.05992775 Z M77.1599876,4.26414545 C77.1599876,3.48860394 76.7673805,2.94103754 76.0525796,2.94103754 C75.4103257,2.94103754 74.9035638,3.49925698 74.82995,4.26414545 L77.1599876,4.26414545 L77.1599876,4.26414545 Z'
                  id='Fill-25'
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
export default AppStoreButton
