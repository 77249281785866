import PropTypes from 'prop-types'
import styled from 'styled-components'

const PrimarySection = styled.div`
  background-size: cover;
  padding: 7rem 0 8rem 0;
  width: 100%;
  margin-bottom: -5rem;

  @media(min-width: 1050px){
    padding-top: 0;
    padding-bottom: 0;
    background: none;
    width: ${props => props.width || '48%'};
    margin-bottom: 0;
  }
`

PrimarySection.propTypes = {
  backgroundImage: PropTypes.string,
  width: PropTypes.string
}

export default PrimarySection
