import React from 'react'
import { useSortable } from '@dnd-kit/sortable'
import Winery from '../../Winery'
import { CSS } from '@dnd-kit/utilities'

export function ItinearyWinery (props) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable({ id: props.id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  }
  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <Winery {...props} />
    </div>
  )
}
