import React, { useMemo } from 'react'
import styled from 'styled-components'
import sortBy from 'lodash/sortBy'
import map from 'lodash/map'
import reject from 'lodash/reject'
import Header from './Header'
import RegionHeader from './RegionHeader'
import Winery from './Winery'
import NoWineries from './NoWineries'
import NoVisitedWineries from './NoVisitedWineries'

const Container = styled.div`
  max-width: 1190px;
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0 1rem;

  @media (min-width: 1190px) {
    padding: 0;
  }
`

const WineriesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  box-sizing: border-box;
  flex-wrap: wrap;
`

function renderWineryGroup (group, token, onMutationSuccess) {
  if (group.wineries.length === 0) return null
  const sortedWineries = sortBy(group.wineries, winery => winery.name)
  return (
    <Container key={group.region.name}>
      <RegionHeader region={group.region} />
      <WineriesContainer>
        {sortedWineries.map(winery =>
          <Winery
            key={winery.id}
            winery={winery}
            region={group.region}
            visited={winery.visited}
            token={token}
            onMutationSuccess={onMutationSuccess}
          />
        )}
      </WineriesContainer>
    </Container>
  )
}

export default function FavoriteWineries ({ wineries: initWineries, user, visited = false}) {
  const [wineries, setWineries] = React.useState(initWineries)

  const count = useMemo(() => {
    return wineries.reduce((count, group) => count + group.wineries.length, 0)
  }, [wineries])

  const onMutationSuccess = React.useCallback((response) => {
    if (response.data.data.removeFavorite) {
      const removedId = parseInt(response.data.data.removeFavorite.winery.id)
      const newWineries = map(this.state.wineries, group => {
        const wineries = reject(group.wineries, { id: removedId })
        group.wineries = wineries
        return group
      })

      setWineries(newWineries)
    }
  })

  if (count === 0) {
    if(visited){
      return <NoVisitedWineries />
    }else{
      return <NoWineries />
    }
  }

  return (
    <div className='fav-wineries'>
      <Header count={count} visited={visited} />
      {wineries.map(group => renderWineryGroup(group, user.token, onMutationSuccess))}
    </div>
  )
}
