import styled, { css } from 'styled-components'
import breakpoints from '../../breakpoints'

const Container = styled.div`
background-color: #fff;
width: 100%;
height: calc(100vh - 72px);
display: flex;
flex-direction: column;
max-height: 100vh;
z-index: 2;
box-shadow: 1px 0 18px 0 rgba(0, 0, 0, 0.05);
overflow-y: hidden;
order: 2;
-ms-grid-column: 2;

@media print {
  overflow-y: visible;
}

${props => props.active && css`
  order: 1;
  -ms-grid-column: 1;
`}

@media (min-width: ${breakpoints.columns}px) {
  order: 1;
  -ms-grid-column: 1;
}
`

export default Container
