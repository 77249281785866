import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import PinIcon from './assets/PinIcon'
import { colors } from '../common'

const Container = styled.div`
  text-align: center;
  width: 26px;
  position: relative;

  ${props => props.isOnMap && css`
    transform: translate(-50%, -80%);
  `}
`

const Text = styled.div`
  font-weight: 600;
  font-size: 0.75rem;
  color: #fff;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-top: 0.375rem;
  position: absolute;
  top: 0;
  left: 0;
`

const Pin = ({ onMap, text, green }) => {
  let color = colors.roseVale
  if (green) {
    color = colors.silverTree
  }
  return (
    <Container isOnMap={onMap}>
      <PinIcon color={color} />
      <Text>{text}</Text>
    </Container>
  )
}

Pin.propTypes = {
  text: PropTypes.number,
  onMap: PropTypes.bool,
  green: PropTypes.bool
}

export default Pin
