import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import fonts from '../common/fonts'

const Icon = styled.img`
  width: 76px;
  align-self: center;
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
  flex: 1;
`

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  align-items: flex-start;
  margin-bottom: 2.5rem;
`

const Heading = styled.p`
  font-family: ${fonts.dinBold};
  color: #fff;
  font-size: 1.375rem;
  font-weight: normal;
  margin: 0;
  text-shadow: 0 2px 4px rgba(0,0,0,0.5);
`

const Description = styled.p`
  color: #fff;
  font-size: 1.125rem;
  margin: 0.5rem 0 0 0;
  text-shadow: 0 2px 4px rgba(0,0,0,0.5);
`

const ValueProp = (props) => {
  return (
    <Container>
      {props.IconComponent != null && (
        <props.IconComponent />
      )}
      <Icon src={props.icon} />
      <TextWrapper>
        <Heading>{props.heading}</Heading>
        <Description>{props.description}</Description>
      </TextWrapper>
    </Container>
  )
}

ValueProp.propTypes = {
  icon: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string
}

export default ValueProp
