import React from 'react'
import ModalVideo from 'react-modal-video'
import styled from 'styled-components'
import { darken } from 'polished'
import { colors } from '../common'

const PlayButton = styled.button`
  cursor: pointer;
  box-sizing: content-box;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 165px;
  transition: 0.2s ease-in-out;
  border-radius: 3px;
  background-color: ${colors.roseVale};
  text-decoration: none;
  color: #fff;
  border: none;
  padding: 12px 18px;

  &:hover {
    background-color: ${darken(0.15, colors.roseVale)};
  }

  i {
    font-size: 1.5rem;
    margin-left: 0.75rem;
  }
`

export default class extends React.Component {
  state = {
    isOpen: false
  }

  constructor () {
    super()
    this.handlePlayClick = this.handlePlayClick.bind(this)
  }

  handlePlayClick () {
    this.setState({ isOpen: true })
  }

  render () {
    return (
      <div>
        <ModalVideo
          channel='youtube'
          isOpen={this.state.isOpen}
          videoId={this.props.videoId}
          youtube={{
            autoplay: 1,
            cc_load_policy: 1,
            controls: 1,
            disablekb: 0,
            enablejsapi: 1,
            fs: 1,
            iv_load_policy: 1,
            loop: 0,
            rel: 0,
            showinfo: 1,
            start: 0,
            wmode: 'transparent',
            theme: 'dark',
            mute: 0
          }}
          onClose={() => this.setState({ isOpen: false })}
        />
        <PlayButton
          color={this.props.buttonColor}
          onClick={this.handlePlayClick}
        >
          {this.props.buttonText}
          {this.props.icon && (
            <i className='fa fa-caret-right' />
          )}
        </PlayButton>
      </div>
    )
  }
}
