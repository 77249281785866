import * as React from 'react'
import { createContext, useReducer } from 'react'

export const DirectionsContext = createContext(null)
export const DirectionsDispatchContext = createContext(null)

export function DirectionsProvider ({ children, initialState = {} }) {
  const [directions, dispatch] = useReducer(
    directionsReducer,
    initialState
  )

  return (
    <DirectionsContext.Provider value={directions}>
      <DirectionsDispatchContext.Provider value={dispatch}>
        {children}
      </DirectionsDispatchContext.Provider>
    </DirectionsContext.Provider>
  )
}

function directionsReducer (directions, action) {
  switch (action.type) {
    case 'fetchStart': {
      return { ...action.payload.address }
    }
    case 'fetchSucceed': {
      return { ...action.payload }
    }
    case 'fetchFail':
      return {}
    default: {
      throw Error('Unknown Action: ' + action.type)
    }
  }
}
