import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { colors, fonts } from '../common'
import trailLogo from './assets/trailLogo.png'
import CloseIcon from './CloseIcon'

const Container = styled.div`
  background-color: #fff;
  text-decoration: none;
  padding: 1.125rem 1.875rem 1.125rem 1.125rem;
  border-radius: 4px;
  box-shadow: 0 1px 2px 1px rgba(0,0,0,0.05);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  max-width: 400px;
  width: 100%;
  position: fixed;
  z-index: 2;
  bottom: 1rem;
  right: 1rem;
  cursor: pointer;
  transition: 0.3s ease-in;
  text-transform: none;

  &:hover {
    box-shadow: 0 1px 4px 2px rgba(0,0,0,0.1);
    transform: translate3d(0, -2px, 0);
  }

  &.fade-exit.fade-exit-active {
    opacity: 0;
    transform: translate3d(0, -10px, 0);
  }

  ${props => props.floatBreakpoint && css`
    @media (min-width: ${props.floatBreakpoint}) {
      position: relative;
      bottom: 0;
      right: 0;
      left: 0;
    }
  `}
`

Container.propTypes = {
  floatBreakpoint: PropTypes.string
}

const LinkContainer = Container.withComponent('a')

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0.65rem;
  align-items: flex-start;
`

const Heading = styled.p`
  font-family: ${fonts.markBold};
  font-weight: normal;
  font-size: 0.8rem;
  color: ${props => props.headingColor ? props.headingColor : '#002863'};
  margin: 0 0 0.1rem 0;
  text-align: left;
`

const Text = styled.p`
  color: ${colors.daintree};
  font-size: 0.75rem;
  font-weight: normal;
  margin: 0;
  max-width: 245px;
  line-height: 1.25;
  text-align: left;
`

const Link = styled.a`
  color: ${colors.viking};
  transition: color 0.35s ease;
  &:hover {
    color: ${colors.vikingDark};
  }
`

const Logo = styled.img`
  max-width: 90px;
`

const Close = styled.div`
  position: absolute;
  top: 1.125rem;
  right: 1.125rem;
  cursor: pointer;
  opacity: 0.5;
  transition: 0.15s ease-in;

  &:hover {
    opacity: 1;
  }
`

class GovcupCta extends React.Component {
  constructor (props) {
    super(props)
    this.state = { show: false }
  }

  componentDidMount () {
    this.getClosedState()
  }

  getClosedState () {
    if (this.props.localStorage && window.localStorage) {
      if (window.localStorage.getItem('wineTrailClose') !== 'true') {
        this.setState({ show: true })
      }
    } else {
      this.setState({ show: true })
    }
  }

  saveClosed (e) {
    e.preventDefault()
    e.stopPropagation()
    this.setState({ show: false })
    if (this.props.localStorage && window.localStorage) {
      window.localStorage.setItem('wineTrailClose', 'true')
    }
  }

  render () {
    return (
      <TransitionGroup>
        {this.state.show && (
          <CSSTransition classNames='fade' in={this.state.show} timeout={300}>
            <LinkContainer
              href={this.props.url} target='_blank'
              floatBreakpoint={this.props.floatBreakpoint}
            >
              <Logo src={trailLogo} />
              <Content>
                <Heading color={this.props.headingColor}>{this.props.heading}</Heading>
                <Text>{this.props.text}</Text>
                {this.props.children}
                <Close onClick={this.saveClosed.bind(this)}>
                  <CloseIcon />
                </Close>
              </Content>
            </LinkContainer>
          </CSSTransition>
        )}
      </TransitionGroup>
    )
  }
}

GovcupCta.propTypes = {
  localStroage: PropTypes.bool,
  floatBreakpoint: PropTypes.string,
  phoneIcon: PropTypes.bool,
  heading: PropTypes.string,
  text: PropTypes.string,
  url: PropTypes.string
}

GovcupCta.Text = Text
GovcupCta.Link = Link

export default GovcupCta
