import React from 'react'
import PropTypes from 'prop-types'
import ActionPill from '../ActionPill'
import SignUpModal from '../SignUpModal'

class ActionPillWithModal extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      modalActive: false,
      modalHeading: 'Sign up for Virginia Wine'
    }
  }

  /*
   * Given a key corresponding to an active state for one of the ActionPill's
   * actions, returns a string for the modal header.
   * @param {String} - key
   * @return {String}
   */
  getHeadingFromKey (key) {
    return {
      favoritesActive: `Sign up to favorite ${this.props.wineryName}`,
      visitedActive: `Sign up to mark ${this.props.wineryName} as visited`,
      itineraryActive: `Sign up to add ${this.props.wineryName} to your itinerary`
    }[key]
  }

  /*
   * Toggles the modal active state and sets the modal heading.
   * @param {String} - key
   * @return {void}
   */
  toggleModal (key) {
    this.setState({
      modalActive: !this.state.modalActive,
      modalHeading: this.getHeadingFromKey(key)
    })
  }

  render () {
    return (
      <div>
        <ActionPill
          noTokenAction={this.toggleModal.bind(this)}
          {...this.props}
        />
        <SignUpModal
          closeModal={this.toggleModal.bind(this)}
          heading={this.state.modalHeading}
          active={this.state.modalActive}
        />
      </div>
    )
  }
}

ActionPillWithModal.defaultProps = {
  wineryName: 'this winery'
}

ActionPillWithModal.propTypes = {
  wineryName: PropTypes.string
}

export default ActionPillWithModal
