import React from 'react'
import styled from 'styled-components'
import {
  DesktopBackground,
  PrimarySection,
  PrimarySectionContent,
  PrimarySectionHeading,
  PrimarySectionText,
  SecondarySection,
  ValueProp
} from '../LandingPage'
import background from './assets/bg-photo.jpg'
import iphoneMocks from './assets/iphone-mocks.png'
import iphoneMocks2X from './assets/iphone-mocks@2x.png'

import FavoriteIcon from './FavoriteIcon'
import ItineraryIcon from './ItineraryIcon'
import DownloadIcon from './DownloadIcon'
import AppStoreButton from './AppStoreButton'
import PlaystoreButton from './PlaystoreButton'

const ValueProps = styled.div`
  margin-top: 2rem;
`

const Mocks = styled.img`
  width: 100%;
`
const StoreLink = styled.a`
  text-decoration: none;

  &:first-of-type {
    margin-right: 1rem;
  }
`

export default () => (
  <DesktopBackground backgroundImage={background}>
    <PrimarySection backgroundImage={background} width='40%'>
      <PrimarySectionContent>
        <PrimarySectionHeading>
          Meet the Virginia Wine Mobile App
        </PrimarySectionHeading>
        <PrimarySectionText>
          Experience VA Wine Country like never before!
        </PrimarySectionText>
        <StoreLink
          href='https://itunes.apple.com/us/app/virginia-wine/id1295174910?ls=1&mt=8'
          target='_blank'
        >
          <AppStoreButton />
        </StoreLink>
        <StoreLink
          href='https://play.google.com/store/apps/details?id=org.virginiawine.virginiawine'
          target='_blank'
        >
          <PlaystoreButton />
        </StoreLink>
        <ValueProps>
          <ValueProp
            iconComponent={FavoriteIcon}
            heading='Favorite Wineries'
            description='Get notified of upcoming events and promotions from your favorite wineries.'
          />
          <ValueProp
            iconComponent={ItineraryIcon}
            heading='Create Itineraries'
            description='Outline the perfect wine-tasting trip for your next group outing.'
          />
          <ValueProp
            iconComponent={DownloadIcon}
            heading='Download the App'
            description='Have Virginia Wine with you throughout your trip.'
          />
        </ValueProps>
      </PrimarySectionContent>
    </PrimarySection>
    <SecondarySection transparent padding='0' width='60%'>
      <Mocks
        src={iphoneMocks}
        srcSet={`${iphoneMocks} 669w, ${iphoneMocks2X} 1338w`}
        alt=''
      />
    </SecondarySection>
  </DesktopBackground>
)
