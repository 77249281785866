import styled, { css } from 'styled-components'
import colors from '../colors'

export default styled.label`
  font-weight: normal;
  margin-bottom: 0.5rem;
  display: block;
  color: ${colors.daintree};

  span {
    color: #8b999f;
    display: block;
  }

  a {
    color: ${colors.viking};
  }

  ${props => props.smallText && css`
    font-size: 0.875rem;
    max-width: 345px;
  `}
`
