import PropTypes from 'prop-types'
import styled from 'styled-components'
import colors from '../colors'
import fonts from '../fonts'

const Heading = styled.h1`
  text-align: ${props => props.textAlign || 'left'};
  font-size: 1.75rem;
  font-weight: normal;
  color: ${colors.daintree};
  margin-top: 0;
  font-family: ${fonts.dinMedium};
`

Heading.propTypes = {
  textAlign: PropTypes.string,
  condensed: PropTypes.bool
}

export default Heading
