import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Transition } from 'react-transition-group'
import { createPortal } from 'react-dom'
import {
  Button,
  FacebookButton,
  Field,
  Heading,
  Link,
  Paragraph
} from '../common'
import colors from '../common/colors'

const breakpoints = {
  mobile: 450
}

const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0.85);
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  transition: 0.3s;

  opacity: ${({ state }) => (state === 'entered' ? 1 : 0)};
  display: ${({ state }) => (state === 'exited' ? 'none' : 'flex')};
`

const Container = styled.div`
  background-color: #fff;
  text-align: center;
  display: block;
  width: 500px;
  box-sizing: border-box;
  padding: 3rem 1.5rem;
  position: relative;

  @media (min-width: ${breakpoints.mobile}px) {
    padding: 3rem;
  }
`

const CloseIcon = () => (
  <svg width='16px' height='16px' viewBox='0 0 16 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-472.000000, -15.000000)' fill='#8B999F'>
        <path d='M479.229699,23 L472,30.2291697 L472.769715,31 L480,23.7702445 L487.230285,31 L488,30.2291697 L480.770301,23 L488,15.7708303 L487.230285,15 L480,22.2297555 L472.769715,15 L472,15.7708303 L479.229699,23 Z' />
      </g>
    </g>
  </svg>
)

const CloseButton = styled.div`
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.2s ease-in;

  &:hover {
    opacity: 1;
  }
`

const EmailSignUpButton = () => (
  <Button
    href='/register'
    envelopeIcon
    fullWidth
    large
    color={colors.silverTree}
  >
    Sign up with Email
  </Button>
)

class SignUpModal extends React.Component {
  stopClickPropagation (e) {
    e.stopPropagation()
  }

  handleOverlayClick = this.props.closeModal

  render () {
    return createPortal(
      <Transition in={this.props.active} timeout={300}>
        {(state) => (
          <Overlay state={state} onClick={this.handleOverlayClick}>
            <Container onClick={this.stopClickPropagation.bind(this)}>
              <CloseButton onClick={this.handleOverlayClick}>
                <CloseIcon />
              </CloseButton>
              <Heading condensed textAlign='center'>
                {this.props.heading || 'Sign Up For Virginia Wine'}
              </Heading>
              <Field fullWidth>
                <FacebookButton url='/auth/facebook'>
                  Log in with Facebook
                </FacebookButton>
              </Field>
              <Field fullWidth><EmailSignUpButton /></Field>
              <Paragraph fullWidth textAlign='center'>
                Already have a Virginia Wine account? <Link href='/login'>Log In</Link>
              </Paragraph>
            </Container>
          </Overlay>
        )}
      </Transition>
      , document.body)
  }
};

SignUpModal.propTypes = {
  closeModal: PropTypes.func,
  heading: PropTypes.string
}

export default SignUpModal
export { Overlay, Container, CloseButton }
