import React from 'react'

export default () => (
  <svg width='13px' height='8px' viewBox='0 0 13 8' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
    <g strokeWidth='1' fillRule='evenodd' transform='translate(-44.000000, -11.000000)'>
      <polygon points='44 14.0502322 44.8026273 13.2991068 49.2689186 17.4954627 56.1985925 11 57 11.7522687 49.2670889 19' />
    </g>
  </svg>
)

