import styled from 'styled-components'

export default styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: 630px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`
