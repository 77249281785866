import React from 'react'
// import PropTypes from 'prop-types'
import styled from 'styled-components'
import MyItinerary from '../MyItinerary'
import { colors } from '../common'
import { WineriesProvider } from '../MyItinerary/WineriesContext'
import { StartAddressProvider } from '../MyItinerary/StartAddressContext'
import { DirectionsProvider } from '../MyItinerary/DirectionsContext'
import { UserProvider } from '../MyItinerary/UserContext'

const Container = styled.div`
  border-top: 1px solid ${colors.quillGrey};
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 100%;
  -ms-grid-rows: auto 1fr;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr;
  width: 100vw;
  height: calc(100vh - 72px);
`

const ItineraryPage = ({ token, wineries, startAddress, loggedIn, googleApiKey }) => {
  return (
    <UserProvider initialState={token}>
      <WineriesProvider initialState={wineries}>
        <StartAddressProvider initialState={startAddress}>
          <DirectionsProvider initialState={{}}>
            <Container>
              <MyItinerary token={token} loggedIn={loggedIn} googleApiKey={googleApiKey} />
            </Container>
          </DirectionsProvider>
        </StartAddressProvider>
      </WineriesProvider>
    </UserProvider>
  )
}

export default ItineraryPage
