export default {
  roseVale: '#ad454f',
  daintree: '#06272d',
  daintree_80: '#395A60',
  daintree_40: '#9ba8ab',
  viking: '#49a9c5',
  vikingDark: '#36869b',
  silverTree: '#71b790',
  hintOfRed: '#fafaf9',
  quillGrey: '#d8d7d6',
  errorRed: '#ED5551',
  white: '#ffffff',
  black: '#000000',
  facebook: '#3b5998',
  lightCoral: '#f2827f',
  stormGrey: '#616060'
}
