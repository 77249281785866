import React from 'react'

export default () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='26' height='51' viewBox='0 0 26 51'>
    <g fill='#8B999F' fillRule='nonzero'>
      <path d='M4.68 0C2.08 0 0 2.242 0 5.044v40.912C0 48.758 2.08 51 4.68 51h16.64c2.6 0 4.68-2.242 4.68-5.044V5.044C26 2.242 23.92 0 21.32 0H4.68zm19.24 45.956c0 1.57-1.144 2.802-2.6 2.802H4.68c-1.456 0-2.6-1.233-2.6-2.802v-5.604h21.84v5.604zm0-7.846H2.08V12.33h21.84v25.78zm0-33.066v5.044H2.08V5.044c0-1.57 1.144-2.802 2.6-2.802h16.64c1.456 0 2.6 1.233 2.6 2.802z' />
      <path d='M14.522 5H11.13c-.621 0-1.13.267-1.13.593 0 .326.509.593 1.13.593h3.392c.621 0 1.13-.267 1.13-.593 0-.326-.509-.593-1.13-.593zM14.522 44H11.13c-.621 0-1.13.267-1.13.593 0 .326.509.593 1.13.593h3.392c.621 0 1.13-.267 1.13-.593 0-.326-.509-.593-1.13-.593z' />
    </g>
  </svg>
)
