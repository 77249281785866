import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { darken } from 'polished'
import colors from '../colors'

const Button = styled.a`
  background-color: transparent;
  text-decoration: none;
  font-weight: 500;
  font-size: 0.9375rem;
  color: ${colors.quillGrey};
  display: inline-block;
  padding: 0.7rem 1.25rem;
  border: 1px solid ${colors.quillGrey};
  border-radius: 5px;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.3s ease-in;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    color: ${colors.white};
  }

  &:active {
    background-color: rgba(0, 0, 0, 0.2);
  }

  ${props => props.fullWidth && css`
    width: 100%;
  `}

  ${props => props.large && css`
    font-weight: 700;
    font-size: 1.125rem;
    letter-spacing: 0.5px;
    padding: 1rem;
  `}

  ${props => props.color && css`
    background-color: ${props => props.color};
    color: #fff;
    border: 1px solid ${props => props.color} !important;
    opacity: 1;

    &:hover {
      background-color: ${props => darken(0.1, props.color)};
      border-color: ${props => darken(0.1, props.color)} !important;
    }

    &:active {
      background-color: ${props => darken(0.1, props.color)};
      border-color: ${props => darken(0.1, props.color)} !important;
    }
  `}

  ${props => props.transparent && css`
    background-color: transparent;
    border-color: ${colors.daintree};
    color: ${colors.daintree};

    &:hover {
      color: ${colors.daintree};
    }
  `}

  ${props => props.envelopeIcon && css`
    ${'' /* background-image: url(${envelopeIcon}); */}
    background-repeat: no-repeat;
    background-position: 12px center;
  `}

  ${props => props.textColor && css`
    color: ${props.textColor};

    &:hover {
      color: ${props.textColor};
    }
  `}
`

Button.propTypes = {
  fullWidth: PropTypes.bool,
  large: PropTypes.bool,
  color: PropTypes.string,
  envelopeIcon: PropTypes.bool,
  textColor: PropTypes.string
}

export default Button
