import React from 'react'
import styled from 'styled-components'
import { colors, fonts } from '../../../common'
import breakpoints from '../../breakpoints'
import PrintIcon from '../../assets/PrintIcon'

const PrintButton = styled.a`
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  transition: 0.15s ease-in;
  display: none;

  @media (min-width: ${breakpoints.columns}px) {
    display: inline-block;
  }

  svg {
    fill: rgba(0,0,0,0.3);
    transition: 0.15s ease-in;
    margin-right: 0.5rem;
  }

  &:hover {
    color: #000;

    svg {
      fill: #000;
    }
  }
`

const SummaryBar = styled.div`
  font-family: ${fonts.markMedium};
  font-size: 1rem;
  color: rgba(0,0,0,0.3);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 1.25rem;
  border-top: 1px solid ${colors.quillGrey};
`

const SummaryText = styled.p`
  margin: 0;
  font-size: 0.875rem;
`

export default function Summary ({ wineryCount, distance, duration }) {
  return (
    <SummaryBar>
      <SummaryText>
        <strong>{wineryCount} {wineryCount === 1 ? 'winery' : 'wineries'}</strong> with
        approx <strong>{distance}</strong> travel distance
        & <strong>{duration}</strong> driving time.
      </SummaryText>
      <PrintButton onClick={() => { window.print() }}>
        <PrintIcon />
        Print
      </PrintButton>
    </SummaryBar>
  )
}
