import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import colors from '../common/colors'

const Container = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  display: inline-flex;
  flex-direction: row;
  border: 1px solid #fff;
  border-radius: 50px;

  ${props => props.light && css`
    background-color: #fff;
    border: 1px solid ${colors.daintree_80};
  `}
`

Container.propTypes = {
  light: PropTypes.bool
}

export default Container
