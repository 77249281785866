import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { fonts, colors, Heading } from '../common'
import MessageBox from '../MessageBox'
import breakpoints from './breakpoints'

const Wrapper = styled.div`
  box-shadow: inset 0 -1px 0 0 #e6e6e6;
  width: 100%;
  padding: 3rem 0 2rem 0;
`

const Container = styled.div`
  max-width: 1190px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0 1rem;
  box-sizing: border-box;

  @media (min-width: ${breakpoints.tablet}) {
    flex-direction: row;
    align-items: center;
  }

  @media (min-width: 1190px) {
    padding: 0;
  }
`

const HeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const FavoritesCount = styled.span`
  color: ${colors.daintree_80};
  font-size: 1rem;
  font-weight: 500;
  margin-left: 0.5rem;
  font-family: ${fonts.markMedium};
`

const FavoritesHeading = styled(Heading)`
  margin: 0 0 0 1rem;
  font-family: ${fonts.dinMedium};
  font-size: 2.25rem;
`

const renderWineryCount = (count) => (
  <FavoritesCount>
    <strong>
      {count}
    </strong>
    {count > 1 ? ' wineries' : ' winery'}
  </FavoritesCount>
)

renderWineryCount.propTypes = {
  count: PropTypes.number
}

const Header = ({visited, count}) => (
  <Wrapper>
    <Container>
      <HeadingContainer>
        { visited ? (
          <svg 
          width="34px" 
          height="30px" 
          viewBox="0 0 20 22" 
          fill="none" 
          xmlns="http://www.w3.org/2000/svg"
          >
<path d="M16.914 10.105C16.9706 9.73938 16.9994 9.36998 17 9C17 6.87827 16.1571 4.84344 14.6569 3.34315C13.1566 1.84285 11.1217 1 9 1C6.87827 1 4.84344 1.84285 3.34315 3.34315C1.84285 4.84344 1 6.87827 1 9C1 13.993 6.539 19.193 8.399 20.799C8.57228 20.9293 8.7832 20.9998 9 20.9998C9.2168 20.9998 9.42772 20.9293 9.601 20.799C9.8799 20.5578 10.1546 20.3117 10.425 20.061" stroke="#9F3638" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9 12C10.6569 12 12 10.6569 12 9C12 7.34315 10.6569 6 9 6C7.34315 6 6 7.34315 6 9C6 10.6569 7.34315 12 9 12Z" fill="#9F3638" stroke="#9F3638" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13 17H19" stroke="#9F3638" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16 14V20" stroke="#9F3638" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
) : (        
          <svg
          width='34px'
          height='30px'
          viewBox='0 0 34 30'
          version='1.1'
          xmlns='http://www.w3.org/2000/svg'
        >
          <g
            stroke='none'
            strokeWidth='1'
            fill='none'
            fillRule='evenodd'
          >
            <g
              transform='translate(-163.000000, -128.000000)'
              fill={colors.roseVale}
            >
              <g transform='translate(163.000000, 118.000000)'>
                <path
                  d='M16.413,39.1519671 C3.553,28.6772586 0.07,24.9900092 0.07,19.0006041 C0.07,14.076271 4.076,10.07 9,10.07 C13.115,10.07 15.441,12.4071581 17,14.165277 C18.559,12.4071581 20.885,10.07 25,10.07 C29.924,10.07 33.93,14.076271 33.93,19.0006041 C33.93,24.9900092 30.447,28.6772586 17.587,39.1519671 L17,39.6299995 L16.413,39.1519671 Z M1.93,19.0006041 C1.93,24.1319512 5.159,27.5621832 17,37.2298371 C28.841,27.5621832 32.07,24.1319512 32.07,19.0006041 C32.07,15.1023404 28.898,11.9301258 25,11.9301258 C21.43,11.9301258 19.567,14.0552696 18.071,15.7633851 L17,16.9664665 L15.929,15.7633851 C14.433,14.0552696 12.57,11.9301258 9,11.9301258 C5.102,11.9301258 1.93,15.1023404 1.93,19.0006041 Z'
                />
              </g>
            </g>
          </g>
        </svg>
          
        )}

        <FavoritesHeading>
          {visited ? "Visited" : "My Favorites"}
          {count > 0 && renderWineryCount(count)}
        </FavoritesHeading>
      </HeadingContainer>
      <div>
        <MessageBox
          phoneIcon
          localStorage
          floatBreakpoint={breakpoints.tablet}
          heading='Use your favorites on the go!'
          text='Download the app and have your favorite wineries with you on your next trip.'
          url='/app'
        />
      </div>
    </Container>
  </Wrapper>
)

Header.propTypes = {
  count: PropTypes.number,
  visited: PropTypes.bool
}

export default Header
