import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import colors from '../colors'

const Container = styled.div`
  margin: ${props => props.narrow ? '1rem' : '2rem'} 0;
  margin-bottom: ${props => props.narrow ? '1.5rem' : '2.5rem'};
  width: 100%;
  height: 0.5rem;
  line-height: 1rem;
  text-align: center;

  ${props => props.lined && css`
    border-bottom: 1px solid ${colors.daintree_80};
  `}
`

Container.propTypes = {
  lined: PropTypes.bool,
  narrow: PropTypes.bool
}

const Text = styled.span`
  text-transform: uppercase;
  font-weight: 500;
  color: ${colors.daintree_80};
  padding: 0 10px;
  background-color: #fff;
`

const Divider = (props) => (
  <Container lined={props.lined} narrow={props.narrow}>
    <Text>{props.text}</Text>
  </Container>
)

Divider.propTypes = {
  lined: PropTypes.bool,
  narrow: PropTypes.bool
}

export default Divider
