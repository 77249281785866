import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

const Field = styled.div`
  margin-bottom: 1rem;

  @media (min-width: 630px) {
    width: ${props => props.fullWidth ? '100%' : '48%'};
  }

  ${props => props.withCheckbox && css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 2rem;
    justify-content: space-between;

    @media (min-width: 630px) {
      flex-direction: row;
    }
  `}
`

Field.propTypes = {
  fullWidth: PropTypes.bool,
  withCheckbox: PropTypes.bool
}

export default Field
