import React from 'react'
import styled from 'styled-components'

import { colors } from '../common'
import stepOneSmall from '../../images/callout-step-one.png'
import stepOneMedium from '../../images/callout-step-one@2x.png'
import stepOneLarge from '../../images/callout-step-one@3x.png'
import stepTwoSmall from '../../images/callout-step-two.png'
import stepTwoMedium from '../../images/callout-step-two@2x.png'
import stepTwoLarge from '../../images/callout-step-two@3x.png'
import stepThreeSmall from '../../images/callout-step-three.png'
import stepThreeMedium from '../../images/callout-step-three@2x.png'
import stepThreeLarge from '../../images/callout-step-three@3x.png'

const Container = styled.div`
  padding: 0 3.4rem;
`

const Step = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 2.5rem 0;

  img {
    max-width: 162px;
    height: auto;
    margin-right: 2rem;
  }
`

const Heading = styled.h3`
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.8125rem;
  color: ${colors.roseVale};
`

const Text = styled.p`
  font-size: 1.125rem;
  line-height: 1.5;
  color: ${colors.daintree};
  margin: 4px 0;

  a {
    text-decoration: underline;
    color: ${colors.viking};
    transition: 0.3s ease-in;
  }

  a:hover {
    color: ${colors.vikingDark};
  }
`

export default () => (
  <Container>
    <Step>
      <img
        src={stepOneSmall}
        srcSet={`${stepOneSmall} 164w, ${stepOneMedium} 328w, ${stepOneLarge} 492w`}
        alt=''
      />
      <div>
        <Heading>Step One</Heading>
        <Text>
          Start by <a href='/wineries'>browsing</a> all the wineries on the
          site. Then, click on the plus (+) icon to start adding wineries to
          your itinerary.
        </Text>
      </div>
    </Step>
    <Step>
      <img
        src={stepTwoSmall}
        srcSet={`${stepTwoSmall} 164w, ${stepTwoMedium} 328w, ${stepTwoLarge} 492w`}
        alt=''
      />
      <div>
        <Heading>Step Two</Heading>
        <Text>
          When you're all set, jump back to the My Itinerary section, add your
          start address, and re-arrange your list to how you like it.
        </Text>
      </div>
    </Step>
    <Step>
      <img
        src={stepThreeSmall}
        srcSet={`${stepThreeSmall} 164w, ${stepThreeMedium} 328w, ${stepThreeLarge} 492w`}
        alt=''
      />
      <div>
        <Heading>Step Three</Heading>
        <Text>
          Before you leave for your trip, be sure
          to <a href='/app'>download the Virginia Wine app</a> so
          that you always have the itinerary with you.
        </Text>
      </div>
    </Step>
  </Container>
)
