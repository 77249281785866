import React from 'react'

export default () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10'>
    <g fillRule='evenodd'>
      <path d='M4.52 5.95l-.469-.47L9.408.121l.47.47L4.52 5.95' />
      <path d='M10 3.571h-.714V.714H6.429V0H10v3.571M8.214 10H1.071C.481 10 0 9.52 0 8.929V1.786C0 1.195.48.714 1.071.714H5v.715H1.071a.358.358 0 0 0-.357.357v7.143c0 .196.16.357.357.357h7.143c.197 0 .357-.16.357-.357V5h.715v3.929c0 .59-.481 1.071-1.072 1.071' />
    </g>
  </svg>
)
