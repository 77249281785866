import React, { useCallback, useContext, useState } from 'react'
import {
  DndContext,
  DragOverlay,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from '@dnd-kit/sortable'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { WineriesDispatchContext, WineriesContext } from '../../WineriesContext'
import { ItinearyWinery } from './ItineraryWinery'
import Winery from '../../Winery'
import { DirectionsContext } from '../../DirectionsContext'
import { StartAddressContext } from '../../StartAddressContext'
import { reorderWineryInItinerary } from '../../helpers'
import { UserContext } from '../../UserContext'

const legToLegDistanceDurations = (directions, startAddress) => {
  if (directions.routes !== undefined) {
    const route = directions.routes[0]
    const directionDistances = route.legs.map((leg) => {
      return {
        distance: leg.distance.text,
        duration: leg.duration.text
      }
    })

    // If there's no start address, we want to add an empty element in the
    // array so that we shift the DistanceTimes during rendering.
    if (startAddress === null) {
      directionDistances.unshift(null)
    }

    return directionDistances
  }
}

export default function WineryList () {
  const wineriesDispatch = useContext(WineriesDispatchContext)
  const wineries = useContext(WineriesContext)
  const startAddress = useContext(StartAddressContext)
  const directions = useContext(DirectionsContext)
  const token = useContext(UserContext)
  const [activeId, setActiveId] = useState(null)
  const [sorting, setSorting] = useState(false)
  const sensors = useSensors(
    useSensor(PointerSensor, {
    // Require the mouse to move by 10 pixels before activating
    // to allow click events
      activationConstraint: {
        distance: 10
      }
    }),
    useSensor(KeyboardSensor,
      {
        coordinateGetter: sortableKeyboardCoordinates
      }
    )
  )
  const distanceDurations = legToLegDistanceDurations(directions, startAddress)

  const mappedwineries = wineries.map((winery, index) => {
    let distanceDuration = null
    if (distanceDurations) {
      distanceDuration = distanceDurations[index]
    }
    return {
      id: winery.id,
      key: `panel-${winery.id}`,
      index,
      wineryIndex: index + 1,
      winery,
      distanceDuration,
      sorting
    }
  })

  const handleDragEnd = useCallback((event) => {
    const { active, over } = event

    if (active.id !== over.id) {
      const wineryIds = wineries.map(w => w.id)
      const oldIndex = wineryIds.indexOf(active.id)
      const newIndex = wineryIds.indexOf(over.id)
      wineriesDispatch({ type: 'reorder', payload: { oldIndex, newIndex } })
      reorderWineryInItinerary(active.id, newIndex, token)
      setSorting(false)
    }
  }, [wineriesDispatch, wineries])

  function handleDragStart (event) {
    const { active } = event
    setSorting(true)
    setActiveId(active)
  }

  let activeWinery
  if (activeId !== null) {
    activeWinery = mappedwineries.find(winery => winery.id === activeId.id)
  } else {
    activeWinery = null
  }

  return (
    <div style={{ overflowY: 'scroll', flex: 1, paddingTop: '1.25rem' }}>
      <DndContext
        sensors={sensors}
        collisonDetection={closestCenter}
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
        modifiers={[restrictToVerticalAxis]}
      >
        <SortableContext
          items={wineries}
          strategy={verticalListSortingStrategy}
        >
          {mappedwineries.map(wineryProps => (
            <ItinearyWinery {...wineryProps} id={wineryProps.id} key={wineryProps.id} sorting={sorting} />
          ))}
        </SortableContext>
        <DragOverlay>
          {activeId !== null ? <Winery {...activeWinery} id={activeId} /> : null}
        </DragOverlay>
      </DndContext>
    </div>

  )
}
