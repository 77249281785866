import React from 'react'
import styled from 'styled-components'
import { colors, Button, fonts } from '../../../common'
import breakpoints from '../../breakpoints'
import ExternalIcon from '../../assets/ExternalIcon'
import ItineraryIcon from '../../assets/ItineraryIcon'

const HeaderSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.1875rem 2rem;
  border-bottom: 1px solid ${colors.quillGrey};

  ${Button} {
    display: none;

    @media (min-width: 650px) {
      display: inline-block;
    }
  }
`

const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
`

const Heading = styled.h2`
  font-family: ${fonts.dinMedium};
  font-size: 2rem;
  font-weight: 500;
  color: ${colors.daintree};
  margin: 0 0 0 0.75rem;
  padding-top: 0.5rem;

  @media (min-width: ${breakpoints.heading}px) {
    font-size: 2.25rem;
  }

  a {
    font-family: ${fonts.markMedium};
    font-size: 0.8125rem;
    color: ${colors.viking};
    display: inline-block;
    margin-left: 0.75rem;
    transition: 0.15s ease-in;

    svg {
      fill: ${colors.viking};
      margin-left: 0.25rem;
      transition: 0.15s ease-in;
    }
  }

  a:hover {
    color: ${colors.vikingDark};

    svg {
      fill: ${colors.vikingDark};
    }
  }
`

export default function Header ({ loggedIn, directionsURL }) {
  return (
    <HeaderSection>
      <HeadingContainer>
        <ItineraryIcon />
        <Heading>
          My Itinerary
          {loggedIn && directionsURL &&
            <a href={directionsURL} target='_blank' rel='noreferrer'>
              Get full directions
              <ExternalIcon />
            </a>}
        </Heading>
      </HeadingContainer>
      {!loggedIn &&
        <Button href='/sign_up' color={colors.silverTree}>
          Sign Up to Create An Itinerary
        </Button>}
      {loggedIn &&
        <Button href='/wineries' color={colors.quillGrey} textColor={colors.daintree}>
          Search for Wineries
        </Button>}
    </HeaderSection>
  )
}
