import PropTypes from 'prop-types'
import styled from 'styled-components'
import colors from '../colors'

const Input = styled.input`
  border: 1px solid ${props => props.error ? colors.errorRed : colors.quillGrey};
  background-color: ${colors.hintOfRed};
  border-radius: 0;
  width: 100%;
  padding: 0.85rem;
  box-sizing: border-box;
  color: ${colors.daintree};
  transition: all 0.3s ease;

  ::placeholder {
    color: ${colors.daintree_40};
  }

  &:focus {
    box-shadow: inset 0 0 3px #67a3ff;
    outline: none;
  }

  &:hover {
    border-color: ${colors.quillGrey};
  }
`

Input.propTypes = {
  error: PropTypes.bool
}

export default Input
