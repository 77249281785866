import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import colors from '../colors'
import fonts from '../fonts'

const Paragraph = styled.p`
  font-family: ${fonts.markMedium};
  font-size: 1rem;
  max-width: 300px;
  margin: 0 auto 1rem auto;
  color: ${colors.daintree};
  line-height: 1.1875;
  text-align: ${props => props.textAlign || 'left'};

  ${props => props.fullWidth && css`
    max-width: 100%;
  `}
`

Paragraph.propTypes = {
  textAlign: PropTypes.string,
  fullWidth: PropTypes.bool
}

export default Paragraph
