import * as React from 'react'
import { createContext, useState } from 'react'

export const UserContext = createContext(null)

export function UserProvider ({ children, initialState = {} }) {
  const [token] = useState(initialState)

  return (
    <UserContext.Provider value={token}>
      {children}
    </UserContext.Provider>
  )
}
