import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Button from '../Button'
import colors from '../colors'

const FacebookSVG = styled.svg`
  position: absolute;
  width: 22px;
  height: 22px;
  top: 50%;
  margin-top: -11px;
  left: 1rem;
`

const FBButton = styled(Button)`
  text-align: center;
`

const FacebookButton = (props) => (
  <FBButton
    href={props.url}
    color={colors.facebook}
    fullWidth
    large
  >
    <FacebookSVG viewBox='0 0 1792 1792' xmlns='http://www.w3.org/2000/svg'>
      <path d='M1579 128q35 0 60 25t25 60v1366q0 35-25 60t-60 25h-391v-595h199l30-232h-229v-148q0-56 23.5-84t91.5-28l122-1v-207q-63-9-178-9-136 0-217.5 80t-81.5 226v171h-200v232h200v595h-735q-35 0-60-25t-25-60v-1366q0-35 25-60t60-25h1366z' fill='#fff' />
    </FacebookSVG>
    {props.children}
  </FBButton>
)

FacebookButton.propTypes = {
  url: PropTypes.string
}

export default FacebookButton
