import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { colors, Heading, Link } from '../common'
import ActionPillWithModal from '../ActionPillWithModal'
import breakpoints from './breakpoints'

const Container = styled.div`
  width: 100%;
  margin: 0 0 2rem 0;

  @media (min-width: ${breakpoints.mobile}) {
    width: 47.5%;
    margin-right: 5%;
    margin-bottom: 5%;

    &:nth-child(2n) {
      margin-right: 0;
    }
  }

  @media (min-width: ${breakpoints.tablet}) {
    width: 30%;

    &:nth-child(2n) {
      margin-right: 5%;
    }

    &:nth-child(3n) {
      margin-right: 0;
    }
  }
`

const LinkWrapper = styled.a`
  text-decoration: none;
  display: block;
  color: ${colors.dainTree};
`

const BannerImage = styled.div`
  background-image: url(${props => props.url}), url("");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 250px;
  position: relative;

  @media(min-width: ${breakpoints.mobile}) {
    height: 18.0vw;
    max-height: 225px;
  }
`

BannerImage.propTypes = {
  url: PropTypes.string
}

const Name = styled(Heading)`
  font-size: 1.5rem;
  margin: 0.25rem 0;
`

const Address = styled.address`
  color: ${colors.daintree_80};
  font-size: 0.75rem;
  text-transform: uppercase;
  line-height: 1.33;
  margin-bottom: 1rem;
  letter-spacing: 0.7px;
`

const RegionName = styled(Link)`
  :before {
    content: " ";
    background-color: ${props => props.color || colors.quillGrey};
    display: inline-block;
    vertical-align: middle;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 0.25rem;
    margin-top: -0.1rem;
  }
`

RegionName.propTypes = {
  color: PropTypes.string
}

const SecondaryInformation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 0.8125rem;
`

const AddedDate = styled.span`color: ${colors.daintree_80};`

const ActionPillContainer = styled.div`
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
`

const Winery = ({ winery, region, token, onMutationSuccess, visited=false }) => {
  return (
    <Container>
      <LinkWrapper href={winery.url}>
        <BannerImage url={winery.banner_image}>
          <ActionPillContainer>
            <ActionPillWithModal
              mini
              token={token}
              wineryID={winery.id}
              wineryName={winery.name}
              favorited={winery.favorited}
              visited={visited}
              inItinerary={winery.inItinerary}
              onMutationSuccess={onMutationSuccess}
            />
          </ActionPillContainer>
        </BannerImage>
      </LinkWrapper>
      <LinkWrapper href={winery.url}>
        <Name>
          {winery.name}
        </Name>
      </LinkWrapper>
      <Address>
        {winery.address.street_address} <br />
        {winery.address.locality}
      </Address>
      <SecondaryInformation>
        <RegionName color={region.accent_color} href={region.url}>
          {region.name}
        </RegionName>
        <AddedDate>
          Added {winery.added_at}
        </AddedDate>
      </SecondaryInformation>
    </Container>
  )
}

Winery.propTypes = {
  winery: PropTypes.object,
  region: PropTypes.object,
  token: PropTypes.string,
  onMutationSuccess: PropTypes.func
}

export default Winery
