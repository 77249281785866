import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import colors from '../common/colors'

const Tooltip = styled.div`
  background-color: #fff;
  font-weight: 600;
  font-size: 0.8rem;
  color: ${colors.silverTree};
  pointer-events: none;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate3d(-50%, -100%, 0);
  white-space: nowrap;
  padding: 0.5rem 0.75rem;
  border: 1px solid #8b999f;
  border-radius: 4px;
  box-shadow: 0 1px 2px 1px rgba(0,0,0,0.05);
  opacity: 0;

  &:before, &:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 50%;
    bottom: 1px;
    transform: translate3d(-50%, 100%, 0);
    border-top: 7px solid #fff;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
  }

  &:before {
    bottom: 0;
    border-top-color: #8b999f;
  }

  ${props => props.mini && css`
    border-color: ${colors.daintree_80};

    &:before {
      border-top-color: ${colors.daintree_80};
    }
  `}
`

Tooltip.propTypes = {
  mini: PropTypes.bool
}

export default Tooltip
