import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

const breakpoints = {
  mobile: 480
}

const Container = styled.div`
  width: 100%;
  max-width: ${props => props.maxWidth || breakpoints.mobile + 'px'};
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0 1px 3px 1px rgba(0,0,0,0.05);
  margin: 0;
  padding: 6rem 2rem 2rem 2rem;

  ${props => props.centerText && css`
    text-align: center;
  `}

  @media (min-width: ${breakpoints.mobile}px) {
    padding: 4rem;
    margin: 0 auto;
  }
`

Container.propTypes = {
  maxWidth: PropTypes.string,
  centerText: PropTypes.bool
}

export default Container
