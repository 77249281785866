import React from 'react'
import axios from 'axios'
import { Motion, spring } from 'react-motion'
import PropTypes from 'prop-types'
import Container from './Container'
import Action from './Action'
import Tooltip from './Tooltip'
import Divider from './Divider'
import HeartIcon from './HeartIcon'
import PlusIcon from './PlusIcon'
import VisitIcon from './VisitIcon'
import CheckIcon from './CheckIcon'

class ActionPill extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      favoritesHover: false,
      itineraryHover: false,
      favoritesActive: this.props.favorited,
      visitedActive:   this.props.visited,
      itineraryActive: this.props.inItinerary
    }
  }

  /*
   * Returns a GraphQL mutation query for a given mutation. These mutations all
   * have the same input parameters so we can write this as one function.
   * @param {String} - mutation
   * @return {Object}
   */
  mutationQuery (mutation) {
    return {
      query: `mutation {
        ${mutation}(input: {
          token: "${this.props.token}",
          winery_id: ${this.props.wineryID}
        }) {
          winery {
            id
          }
          errors
        }
      }`
    }
  }

  /*
   * Given a key and active state, sets the component state and also executes a
   * GraphQL mutation corresponding to the action this method is called from.
   * @param {Event} - e
   * @param {String} - actionHoverKey
   * @param {String} - actionActiveKey
   * @param {Boolean} - active
   * @return {void}
   */
  actionClickHandler (e, actionHoverKey, actionActiveKey, active) {
    e.preventDefault()
    if (this.props.token || this.props.demo) {
      this.setState({ [actionActiveKey]: active })
    }
    if (this.props.token) {
      const mutation = this.getActionMutation(actionActiveKey, active)
      axios.post('/graphql', this.mutationQuery(mutation))
        .then((response) => {
          // If the server responds with an error, revert the state.
          if (response.data.data[mutation].errors !== null) {
            this.setState({ [actionActiveKey]: !active })
          }
          if (this.props.onMutationSuccess) {
            this.props.onMutationSuccess(response)
          }
          if (/Android|iPhone|iPad|iPod/i.test(navigator.userAgent)) {
            setTimeout(() => {
              this.setState({ [actionHoverKey]: false })
            }, 2000)
          }
        })
    } else if (this.props.noTokenAction) {
      this.props.noTokenAction(actionActiveKey)
    }
  }

  /*
   * Given a key and active state, returns a string corresponding to a GraphQL
   * mutation.
   * @param {String} - key
   * @param {Boolean} - active
   * @return {String}
   */
  getActionMutation (key, active) {
    return {
      favoritesActive: active ? 'addFavorite' : 'removeFavorite',
      visitedActive:   active ? 'addVisited' : 'removeVisited',
      itineraryActive: active ? 'addWineryToItinerary' : 'removeWineryFromItinerary'
    }[key]
  }

  /*
   * Sets the props of an <Action> component based on the name passed. The name
   * corresponds to an action in the ActionPill.
   * @param {String} - actionName <"favorites" | "itinerary">
   * @return {Object}
   */
  getTooltipActionProps (actionName = '') {
    const actionHoverKey = `${actionName}Hover`
    const actionActiveKey = `${actionName}Active`
    const actionActive = !this.state[actionActiveKey]
    return {
      onMouseOver: () => { this.setState({ [actionHoverKey]: true }) },
      onMouseOut: () => { this.setState({ [actionHoverKey]: false }) },
      onClick: (e) => {
        this.actionClickHandler.bind(this)(
          e,
          actionHoverKey,
          actionActiveKey,
          actionActive
        )
      },
      active: this.state[actionActiveKey],
      green: actionName === 'itinerary',
      white: actionName === 'visited',
      mini: this.props.mini,
      light: this.props.light,
      action: actionName
    }
  }

  /*
   * Sets the props of a <Motion> component based on the name passed. The name
   * corresponds to an action in the ActionPill. Used for the hover bounce of an
   * <Action>'s <Tooltip>. Opted to use react-motion because doubling up on CSS3
   * animations didn't work.
   * @param {String} - actionName <"favorites" | "itinerary">
   * return {Object}
   */
  getTooltipMotionProps (actionName = '') {
    return {
      defaultStyle: {
        translateY: -100,
        opacity: 0
      },
      style: {
        translateY: spring(this.state[`${actionName}Hover`] ? -120 : -100, {
          stiffness: 400,
          damping: 15
        }),
        opacity: spring(this.state[`${actionName}Hover`] ? 1 : 0)
      }
    }
  }

  /*
   * Sets the props of a <Tooltip> component and gets passed the interpolated
   * values of a <Motion> component.
   * @param {Object} - { motionVals }
   * @return {Object}
   */
  getTooltipProps ({ motionVals }) {
    return {
      style: {
        transform: `translate3d(-50%, ${motionVals.translateY + '%'}, 0)`,
        opacity: motionVals.opacity
      },
      mini: this.props.mini
    }
  }

  render () {
    return (
      <Container mini={this.props.mini} light={this.props.light}>
        
        <Action {...this.getTooltipActionProps('favorites')}>
          <Motion {...this.getTooltipMotionProps('favorites')}>
            {motionVals =>
              <Tooltip {...this.getTooltipProps({ motionVals })}>
                {this.state.favoritesActive ? 'Favorited!' : 'Add to Favorites'}
              </Tooltip>}
          </Motion>
          <HeartIcon />
        </Action>
        
        <Divider mini={this.props.mini} light={this.props.light} />
        <Action {...this.getTooltipActionProps('itinerary')}>
          <Motion {...this.getTooltipMotionProps('itinerary')}>
            {motionVals =>
              <Tooltip {...this.getTooltipProps({ motionVals })}>
                {this.state.itineraryActive ? 'Added!' : 'Add to Itinerary'}
              </Tooltip>}
          </Motion>
          {this.state.itineraryActive ? <PlusIcon /> : <PlusIcon />}
        </Action>
        
        <Divider mini={this.props.mini} light={this.props.light} />
        
        <Action {...this.getTooltipActionProps('visited')}>
          <Motion {...this.getTooltipMotionProps('visited')}>
            {motionVals =>
              <Tooltip {...this.getTooltipProps({ motionVals })}>
                {this.state.visitedActive ? 'Visited!' : 'Mark as Visited'}
              </Tooltip>}
          </Motion>
          <VisitIcon />
        </Action>
        
      </Container>
    );
  }
};

ActionPill.propTypes = {
  demo: PropTypes.bool,
  favorited: PropTypes.bool,
  visited: PropTypes.bool,
  inItinerary: PropTypes.bool,
  token: PropTypes.string,
  wineryID: PropTypes.number,
  onMutationSuccess: PropTypes.func,
  noTokenAction: PropTypes.func
}

export default ActionPill
