import * as React from 'react'

const DownloadIcon = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={76} height={76} {...props}>
    <g fill='none' fillRule='evenodd'>
      <circle cx={38} cy={38} r={38} fill='#FFF' />
      <g fill='#A4343A' fillRule='nonzero'>
        <path d='M29.22 13A5.21 5.21 0 0 0 24 18.242v42.516A5.21 5.21 0 0 0 29.22 66h18.56c2.9 0 5.22-2.33 5.22-5.242V18.242A5.21 5.21 0 0 0 47.78 13H29.22zm21.46 47.758c0 1.631-1.276 2.912-2.9 2.912H29.22c-1.624 0-2.9-1.281-2.9-2.912v-5.824h24.36v5.824zm0-8.154H26.32v-26.79h24.36v26.79zm0-34.362v5.242H26.32v-5.242c0-1.631 1.276-2.912 2.9-2.912h18.56c1.624 0 2.9 1.281 2.9 2.912z' />
        <path d='M40.8 18h-3.6c-.66 0-1.2.45-1.2 1s.54 1 1.2 1h3.6c.66 0 1.2-.45 1.2-1s-.54-1-1.2-1zm-4.657 43h5.714c.629 0 1.143-.45 1.143-1s-.514-1-1.143-1h-5.714c-.629 0-1.143.45-1.143 1s.514 1 1.143 1z' />
      </g>
    </g>
  </svg>
)
export default DownloadIcon
