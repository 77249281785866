import React, { useCallback, useContext, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import { lighten } from 'polished'
import { colors } from '../../../common'
import breakpoints from '../../breakpoints'
import searchIcon from '../../assets/search.svg'
import checkIcon from '../../assets/check.svg'
import PinIcon from '../../assets/PinIcon'
import { GeolocationContent } from './GeolocationContent'
import { StartAddressContext } from '../../StartAddressContext'

const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1rem 1.25rem;
  border-bottom: 1px solid ${colors.quillGrey};

  & > svg {
    display: none;

    @media (min-width: ${breakpoints.columns}px) {
      display: block;
      margin-top: 7px;
    }
  }
`

const SearchbarContainer = styled.div`
  width: 100%;

  @media (min-width: ${breakpoints.columns}px) {
    width: 80%;
  }
`

const Searchbar = styled.input`
  background-image: url(${searchIcon});
  background-repeat: no-repeat;
  background-position: 97% center;
  background-color: #fcfcfc;
  font-size: 1rem;
  color: ${lighten(0.2, colors.daintree)};
  display: block;
  width: 100%;
  height: 48px;
  padding: 0 2.5625rem 0 1.0625rem;
  border: 1px solid rgba(139, 153, 159, 0.3);
  border-radius: 6.1px;
  box-sizing: border-box;
  outline: none;
  appearance: none;

  ${props => props.success && css`
    background-image: url(${checkIcon});
  `}
`

export default function SearchBar ({ onPlaceChange }) {
  const startAddress = useContext(StartAddressContext)
  const input = useRef(null)
  const autocomplete = useRef(null)

  const handlePlaceChange = useCallback(() => {
    if (autocomplete.current) {
      onPlaceChange(autocomplete.current.getPlace())
    }
  }, [autocomplete.current, onPlaceChange])

  useEffect(() => {
    if (input.current !== null && autocomplete.current === null) {
      autocomplete.current = new window.google.maps.places.Autocomplete(input.current, {
        componentRestrictions: { country: 'us' }
      })
      autocomplete.current.addListener('place_changed', handlePlaceChange)

      return () => {
        autocomplete.current.removeListener(handlePlaceChange)
      }
    }
  }, [input])

  if (input.current && startAddress) {
    input.current.value = startAddress.name
  }

  return (
    <SearchContainer>
      <SearchbarContainer>
        <Searchbar
          type='text'
          placeholder='Enter a starting address'
          ref={input}
          success={startAddress !== null}
        />
        <GeolocationContent inputRef={input} />
      </SearchbarContainer>
      <PinIcon color={colors.silverTree} />
    </SearchContainer>
  )
}
