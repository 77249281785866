import find from 'lodash/find'

function addressPart (components, part) {
  if (part === 'street_address') {
    const streetAddressParts = []
    const foundStreetNumber = find(components, component => {
      return component.types.includes('street_number')
    })
    if (foundStreetNumber !== undefined) {
      streetAddressParts.push(foundStreetNumber.long_name)
    }
    const foundRoute = find(components, component => {
      return component.types.includes('route')
    })
    if (foundRoute !== undefined) {
      streetAddressParts.push(foundRoute.long_name)
    }
    return streetAddressParts.join(' ')
  } else if (part === 'administrative_area_level_1') {
    return find(components, component => {
      return component.types.includes(part)
    }).short_name
  } else {
    const foundPart = find(components, component => {
      return component.types.includes(part)
    })
    return foundPart ? foundPart.long_name : ''
  }
}

export function getCoordsFromWinery (winery) {
  return `${winery.address.latitude},${winery.address.longitude}`
}

export function formatPlaceResultAsWinery (placeResult) {
  const { address_components: addr } = placeResult
  return {
    name: placeResult.formatted_address,
    address: {
      latitude: placeResult.geometry.location.lat(),
      longitude: placeResult.geometry.location.lng(),
      street_address: addressPart(addr, 'street_address'),
      locality: addressPart(addr, 'locality'),
      state: addressPart(addr, 'administrative_area_level_1'),
      postal_code: addressPart(addr, 'postal_code')
    }
  }
}

export const geolocationStates = Object.freeze({
  INACTIVE: Symbol('inactive'),
  ACTIVE: Symbol('active'),
  SUCCESS: Symbol('success'),
  FAILURE: Symbol('failure')
})

export const myItineraryModes = Object.freeze({
  LIST: Symbol('list'),
  MAP: Symbol('map')
})
