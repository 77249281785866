import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { lighten } from 'polished'

import colors from '../colors'

const Container = styled.div`
  box-sizing: border-box;
  background-color: ${lighten(0.2, colors.errorRed)};
  border: 1px solid ${colors.errorRed};
  padding: 1rem;
  border-radius: 2px;
  margin-bottom: 2rem;
`

const ErrorList = styled.ul`
  margin: 0;
  padding-left: 1rem;
`

const Error = styled.li`
  color: ${colors.errorRed};
`

const ResourceErrors = ({ errors }) => {
  return (
    <Container>
      <ErrorList>
        {errors.map(error => (<Error key={error}>{error}</Error>))}
      </ErrorList>
    </Container>
  )
}

ResourceErrors.propTypes = {
  errors: PropTypes.array
}

export default ResourceErrors
