import * as React from 'react'

const FavoriteIcon = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={76} height={76} {...props}>
    <g fill='none' fillRule='evenodd'>
      <circle cx={38} cy={38} r={38} fill='#FFF' />
      <path
        fill='#A4343A'
        d='m37.5 57.529-.742-.606C20.494 43.656 16.088 38.986 16.088 31.4c0-6.237 5.067-11.311 11.294-11.311 5.205 0 8.146 2.96 10.118 5.187 1.972-2.227 4.913-5.187 10.118-5.187 6.227 0 11.293 5.074 11.293 11.311 0 7.586-4.404 12.256-20.669 25.523l-.742.606zM27.382 22.445c-4.93 0-8.941 4.018-8.941 8.955 0 6.5 4.084 10.844 19.059 23.089C52.475 42.244 56.56 37.899 56.56 31.4c0-4.937-4.013-8.955-8.942-8.955-4.515 0-6.872 2.691-8.764 4.855L37.5 28.824 36.145 27.3c-1.892-2.164-4.248-4.855-8.763-4.855z'
      />
    </g>
  </svg>
)
export default FavoriteIcon
