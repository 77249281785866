import * as React from 'react'
import { createContext, useReducer } from 'react'

export const StartAddressContext = createContext(null)
export const StartAddressDispatchContext = createContext(null)

export function StartAddressProvider ({ children, initialState = {} }) {
  const [address, dispatch] = useReducer(
    startAddressReducer,
    initialState
  )

  return (
    <StartAddressContext.Provider value={address}>
      <StartAddressDispatchContext.Provider value={dispatch}>
        {children}
      </StartAddressDispatchContext.Provider>
    </StartAddressContext.Provider>
  )
}

function startAddressReducer (address, action) {
  switch (action.type) {
    case 'geocode': {
      return { ...address, ...{ geocode: action.payload.geocode } }
    }
    case 'clear': {
      return { address: {}, geocode: '' }
    }
    default: {
      throw Error('Unknown Action: ' + action.type)
    }
  }
}
