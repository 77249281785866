import styled from 'styled-components'

export default styled.div`
  margin: 0 auto;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
  max-width: 600px;
  box-sizing: border-box;

  @media(min-width: 600px) {
    padding-left: 0;
  }

  @media(min-width: 1050px) {
    width: 100%;
    max-width: none;
  }
`
