import React from 'react'
import PropTypes from 'prop-types'
import styled, { css, keyframes } from 'styled-components'
import colors from '../common/colors'

import Tooltip from './Tooltip'

const bounce = keyframes`
  0% { transform: translate3d(-50%, -100%, 0); }
  50% { transform: translate3d(-50%, -122%, 0); }
  80% { transform: translate3d(-50%, -118%, 0); }
  100% { transform: translate3d(-50%, -120%, 0); }
`

const grow = keyframes`
  0% { transform: scale3d(0, 0, 0); }
  25% { transform: scale3d(0, 0, 0); }
  50% { transform: scale3d(1.6, 1.6, 1); }
  80% { transform: scale3d(1.4, 1.4, 1); }
  100% { transform: scale3d(1.5, 1.5, 1.5); }
`

const growMobile = keyframes`
  0% { transform: scale3d(0, 0, 0); }
  25% { transform: scale3d(0, 0, 0); }
  50% { transform: scale3d(1.1, 1.1, 1); }
  80% { transform: scale3d(0.9, 0.9, 1); }
  100% { transform: scale3d(1, 1, 1); }
`

const ActionButton = styled.button`
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  position: relative;
  padding: 0 1.75rem;
  border: none;
  cursor: pointer;
  outline: none;
  appearance: none;
  transition: 0.1s ease-in;

  &:first-of-type {
    padding-right: 1.4rem;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
  }

  &:last-of-type {
    padding-left: 1.4rem;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
  }

  svg {
    fill: #fff;
    stroke: transparent;
    transform: scale(1.5);
    overflow: visible;
    transition: 0.1s ease-in;
    pointer-events: none;

    &.inverted {
      fill: transparent;
      stroke: #fff;
    }
  }

  &:hover {
    background-color: #fff;

    svg, svg.inverted {
      fill: #000;
    }
  }

  ${props => props.mini && css`
    height: 28px;
    padding-left: 0.75rem;
    padding-right: 0.75rem;

    svg {
      transform: scale(1);
    }

    &:first-of-type {
      padding-right: 0.6rem;
    }

    &:last-of-type {
      padding-left: 0.6rem;
    }
  `}

  ${props => props.light && css`
    svg {
      fill: ${colors.daintree_80};

      &.inverted {
        fill: #fff;
        stroke: ${colors.daintree_80};
      }
    }

    &:hover {
      background-color: ${colors.daintree_80};

      svg {
        fill: #fff;

        &.inverted {
          fill: #fff;
        }
      }
    }
  `}

  ${props => props.active && css`
    &, &:hover {
      background-color: ${props.green ? colors.silverTree : (props.white ? colors.viking : colors.lightCoral)} !important;

      svg, svg.inverted {
        fill: ${props => props.green || props.white ? colors.white : colors.white};
      }

      svg.inverted {
        stroke: transparent !important;
      }
    }

    svg.heart {
      animation: 0.5s ${props.mini ? growMobile : grow} ease;
    }

    ${Tooltip} {
      animation: 0.3s ${bounce} ease;
    }
  `}
`

const Action = function (props) {
  return (
    <ActionButton {...props} data-winery-action={props.action}>
      {props.children}
    </ActionButton>
  )
}

Action.propTypes = {
  mini: PropTypes.bool,
  light: PropTypes.bool,
  active: PropTypes.bool,
  action: PropTypes.string
}

export default Action
