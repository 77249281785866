import * as React from 'react'
import { arrayMove } from '@dnd-kit/sortable'
import { createContext, useReducer } from 'react'

export const WineriesContext = createContext(null)
export const WineriesDispatchContext = createContext(null)

export function WineriesProvider ({ children, initialState = [] }) {
  const [wineries, dispatch] = useReducer(
    wineriesReducer,
    initialState
  )

  return (
    <WineriesContext.Provider value={wineries}>
      <WineriesDispatchContext.Provider value={dispatch}>
        {children}
      </WineriesDispatchContext.Provider>
    </WineriesContext.Provider>
  )
}

function wineriesReducer (wineries, action) {
  switch (action.type) {
    case 'remove': {
      return wineries.filter(winery => winery.id !== action.payload.wineryId)
    }
    case 'reorder': {
      const { oldIndex, newIndex } = action.payload
      return arrayMove(wineries, oldIndex, newIndex)
    }
    default: {
      throw Error('Unknown Action: ' + action.type)
    }
  }
}
