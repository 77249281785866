import PropTypes from 'prop-types'
import styled from 'styled-components'

const SecondarySection = styled.div`
  background-color: ${props => props.transparent ? 'none' : '#fff'};
  box-sizing: border-box;
  padding: ${props => props.padding || '3.25rem'};
  width: 100%;
  max-width: 600px;

  @media(min-width: 1050px) {
    width: ${props => props.width || '48%'};
  }
`

SecondarySection.propTypes = {
  transparent: PropTypes.bool,
  padding: PropTypes.string,
  width: PropTypes.string
}

export default SecondarySection
